/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'wouter';
import { Step } from 'react-rtg';
import { observer } from 'remx';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { del, get } from '../../../libs/client';
import Help from '../../../components/utils/Help';

import {
  CommonInput, CommonSelect, PaginationInput,
} from '../../../components/widgets';
import { QuerifySearch, DecodifySearch } from '../../../utils/StringUtils';
import stores from '../../../libs/stores';

import { EventsTable } from '../../../components/tables';

const PAGINATION_LIMIT = 15;
const PAGES = ['✅ Satıştaki Etkinlikler', '⌛ Onay Bekleyen Etkinlikler', '⭐ Yeni Açılmış Etkinlikler', '🕒 Geçmiş Etkinlikler'];

const Search = ({ disabled, query, onSearch }) => {
  const [defaults] = useState(DecodifySearch(query || window.location.search));
  const { register, reset, handleSubmit } = useForm({ defaultValues: defaults });
  useEffect(() => onSearch({ ...defaults }), []);
  return (
    <main id="search" className="main grid grid-slim align-items-end" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 0.5fr' }}>
      <CommonInput
        label="Etkinlik Adı"
        placeholder="Etkinlik adıyla arayın"
        register={register('name')}
        disabled={disabled}
      />
      <CommonInput
        label="Sanatçı"
        placeholder="Sanatçı adıyla arayın"
        register={register('artist')}
        disabled={disabled}
      />
      <CommonInput
        label="Mekan"
        placeholder="Mekan adıyla arayın"
        register={register('venue')}
        disabled={disabled}
      />
      <CommonInput
        label="Başlangıç"
        type="date"
        disabled={disabled}
        register={register('start')}
      />
      <CommonInput
        label="Bitiş"
        type="date"
        disabled={disabled}
        register={register('end')}
      />
      <CommonSelect
        label="Aktif mi?"
        placeholder="Bir statü seçin"
        disabled={disabled}
        defaultValue={defaults.active}
        options={[
          { value: 'true', name: 'Evet' },
          { value: 'false', name: 'Hayır' },
          { value: '', name: 'Hepsi' },
        ]}
        register={register('active')}
      />
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary btn-normal flex-grow-1"
          disabled={disabled}
          onClick={handleSubmit(onSearch)}
        >
          <i className="fa fa-search" />
        </button>
        {query && (
          <button
            type="button"
            className="btn btn-danger btn-normal flex-grow-1 ml-2"
            onClick={() => {
              onSearch({ ...defaults });
              reset(null);
            }}
          >
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
    </main>
  );
};

const EventsGeneric = ({ deletable, searchBar = true, initialQueries }) => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState('&');

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const GetData = () => get(`/events/search?page=${total}&limit=${PAGINATION_LIMIT}&${initialQueries}&${query || ''}`)
    .then((res) => { setData(res.data); setPages(res.total); })
    .catch(console.log);

  const DeleteEvent = (entity) => window.confirm('Etkinliği silmek istediğinize emin misiniz? Bu işlem geri alınamaz.') && del(`/events/${entity._id}`)
    .then(() => { alert('Etkinlik silindi!'); GetData(); })
    .catch(console.log);

  useEffect(() => { GetData(); }, [query, total]);

  return (
    data
      ? (
        <main className="grid">
          {data?.length > 0
            ? (
              <>
                {searchBar && <Search onSearch={(q) => { setQuery(QuerifySearch(q)); setTotal(0); }} query={query} />}
                <EventsTable
                  onClickActive={() => null}
                  onClickDelete={DeleteEvent}
                  exclusiveQueries="?&step=1"
                  data={data}
                  total={pages}
                  deletable={deletable}
                  index={(PAGINATION_LIMIT * total)}
                  pager={(
                    <p id="table-event-pager" className="d-flex align-items-center mr-2">
                      sayfa
                      <CommonSelect
                        className="mx-2"
                        value={total + 1}
                        placeholder={1}
                        onChange={({ target }) => setTotal(target.value)}
                        options={Array.from(Array(Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT)))
                          .map((_, i) => ({ name: i + 2, value: i + 1 }))}
                      />
                      {`/ ${Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT) + 1}`}
                    </p>
                )}
                />
              </>
            )
            : (
              <CenteredInformation
                title=" Hiç etkinlik yok"
                subtitle="ES Events'ten organizatörlerin etkinlik eklemesi gerekiyor."
                fixed
              />
            )}
        </main>
      )
      : (<LoadingIndicator />)
  );
};

const Events = () => {
  const [page, setPage] = useState(Number(DecodifySearch(window.location.search)?.step) || 0);

  const [help, setHelp] = useState(false);
  stores.setTutorial(() => { setHelp(true); });

  useEffect(() => {
    stores.setAppbar({
      left: (<h2 className="m-0"> Tüm Etkinlikler</h2>),
      right: (
        <Link to="/events/new">
          <button
            id="new-event"
            type="button"
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-2" />
            YENİ ETKİNLİK
          </button>
        </Link>
      ),
    });
    stores.setBreadCrumbs([]);
  }, []);

  return (
    <main className="grid">
      {/* HELP START  */}
      <Help open={help} setOpen={setHelp}>
        <Step selector="#pagination-1" placement="bottom">
          <p>Bu sekmede aktif olarak satışta olan etkinliklerinizi görürsünüz</p>
        </Step>
        <Step selector="#pagination-2" placement="bottom">
          <p>Bu sekmede ES Events ya da mekan onayı bekleyen, satış ve seans bilgisi girilmiş etkinliklerinizi görürsünüz</p>
        </Step>
        <Step selector="#pagination-3" placement="bottom">
          <p>Bu sekmede &quot;yeni açılmış&quot; ve henüz satış veya mekan bilgisi girilmemiş etkinliklerinizi görürsünüz</p>
        </Step>
        <Step selector="#new-event" placement="bottom">
          <p>Yeni etkinlik açmak için bu butonu kullanabilirsiniz</p>
        </Step>
        <Step selector="#pagination-4" placement="bottom">
          <p>Bu sekmede satışta olmayan ya da satışı bitmiş, geçmiş tarihli etkinliklerinizi görürsünüz</p>
        </Step>
        <Step selector="#search" placement="bottom">
          <p>Bu arama kısmından etkinliklerinizi çeşitli parametreler ile arayabilirsiniz</p>
        </Step>
        <Step selector="#table-event" placement="top">
          <p>Seçtiğiniz kategoriye göre etkinlikleriniz burada tarihe göre listelenir</p>
        </Step>
        <Step selector="#table-event-row" placement="top">
          <p>Etkinliğiniz hakkında temel bilgilere hızlıca bu tablodan ulaşabilirsiniz</p>
        </Step>
        <Step selector="#table-event-link" placement="right">
          <p>Etkinlik detayını görmek için linke tıklayabilirsiniz</p>
        </Step>
        <Step selector="#table-event-pager" placement="top">
          <p>Göremediğiniz etkinlik varsa bir sonraki sayfaya bakmayı deneyin</p>
        </Step>
      </Help>
      {/* HELP END  */}

      <PaginationInput
        className="mr-2"
        content={PAGES}
        onChange={setPage}
        page={page}
      />
      {page === 0 && (<EventsGeneric initialQueries="recent=true" Search={Search} />)}
      {page === 1 && (<EventsGeneric initialQueries="status=pending" Search={Search} />)}
      {page === 2 && (<EventsGeneric initialQueries="new=true" deletable searchBar={false} Search={Search} />)}
      {page === 3 && (<EventsGeneric initialQueries="past=true" Search={Search} />)}
    </main>
  );
};

export default observer(Events);
