/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import strings from '../../localization';
import ConfirmationChip from './ConfirmationChip';
import { get, post } from '../../libs/client';
import { CommonInput } from '../widgets';
import OrganizerPaymentDialog from './OrganizerPaymentDialog';

const OrganizerSaleDialog = ({ organizerSale, sale, onClose }) => {
  const { register, handleSubmit } = useForm();

  const UpsertSale = (form) => {
    post('/sales/events/', {
      ...form, sale: sale._id, event: sale.event._id, owner: sale.owner,
    }).then(({ message }) => {
      if (message === 'update-sale-event') {
        alert('Etkinlik ödemesi güncellendi');
      } else {
        alert('Etkinlik ödemesi oluşturuldu');
      }
      onClose();
    }).catch(() => {
      alert('Ödeme oluşturulamadı');
    });
  };

  return (
    <div className="modal" style={{ display: 'block', background: '#000000AA' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Etkinlik Ödemesi</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CommonInput
              label="Organizatör tarafından ödenecek tutarı girin"
              placeholder="1.00"
              register={register('amount', { required: true, valueAsNumber: true })}
              defaultValue={organizerSale?.amount}
              append={<p>EUR</p>}
              type="number"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit(UpsertSale)}
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrganizerSale = ({ sale, params, onPaymentChange }) => {
  const [organizerSale, setOrganizerSale] = useState(null);
  const [payment, setPayment] = useState(null);

  const GetSale = () => {
    if (params.id !== 'new') {
      get(`/sales/events/${sale?.event?._id}/sale/${sale?._id}`)
        .then(({ data }) => { setOrganizerSale(data); })
        .catch(() => alert('Etkinlik ödemesi bulunamadı'));
    }
  };

  useEffect(() => { GetSale(); }, []);
  useEffect(() => {
    if (organizerSale) {
      onPaymentChange(organizerSale);
    }
  }, [organizerSale]);

  return (
    <>
      <div className="mb-0 d-flex flex-column justify-content-end">
        <label title="OKAY">ES Events Ödemesi</label>
        <ConfirmationChip
          label={organizerSale
            ? strings[`event-sale-${organizerSale?.status}`]
            : (params.id === 'new'
              ? 'Önce satış oluşturun'
              : 'Ödeme Girilmemiş')}
          status={organizerSale?.status || 'pending'}
          onClick={() => setPayment(organizerSale)}
          disabled={organizerSale?.status !== 'pending'}
        />
      </div>
      {payment && (
      <OrganizerPaymentDialog
        sale={payment}
        onClose={() => setPayment(null)}
        onSuccess={() => {
          alert('Ödeme Başarılı\nÖdemenizin onaylanması bir kaç dakika sürebilir, lütfen sayfayı tazeleyin.');
          GetSale();
          setPayment(null);
        }}
        onFail={() => {
          alert('Ödeme Başarısız');
          setPayment(null);
        }}
      />
      )}
    </>
  );
};

export default OrganizerSale;
