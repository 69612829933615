const colors = {
  confirmed: 'success', success: 'success', pending: 'warning', denied: 'danger',
};

const ConfirmationChip = ({
  label, status, onClick, disabled,
}) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={`m-0 btn btn-${colors[status]} w-100`}
  >
    {label}
  </button>
);

export default ConfirmationChip;
