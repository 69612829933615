/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import strings from '../../localization';

import CommonInput from '../widgets/CommonInput';
import CommonSelect from '../widgets/CommonSelect';

const EventTicketsTable = ({
  secondaryAction, seating, distribution, setValue,
  register, currency, disabled, onRemove,
  fieldArray, watch, session,
}) => (
  <div>
    <label>Biletler</label>
    <table className="w-100">
      <thead>
        <tr>
          <th>Kategori</th>
          <th>Adı</th>
          <th className="text-right">Fiyatı</th>
          <th className="text-right">Stok</th>
          <th className="text-right">Satılan</th>
          <th className="text-right">Satılacak</th>
          {seating && <th className="text-right">Oturmalı Mı?</th>}
          <th className="text-right">Durumu</th>
          <th className="text-center">Sil</th>
        </tr>
      </thead>
      <tbody>
        {fieldArray.fields.map((ticket, i) => {
          const [stock, seated] = [watch(`tickets.${i}.stock`), watch(`tickets.${i}.seated`)];
          return (
            <tr key={ticket.id}>
              <td>
                <CommonInput
                  id="ticket-category"
                  style={{ width: '5rem' }}
                  disabled={disabled}
                  size="sm"
                  placeholder="Kategori adı"
                  register={register(`tickets.${i}.category`, { required: true, minLength: 1 })}
                />
              </td>
              <td>
                <CommonInput
                  id="ticket-name"
                  style={{ width: '5rem' }}
                  disabled={disabled}
                  size="sm"
                  placeholder="Satış adı"
                  register={register(`tickets.${i}.name`, { required: true, minLength: 1 })}
                />
              </td>
              <td align="right">
                <CommonInput
                  id="ticket-price"
                  disabled={disabled}
                  style={{ width: '7rem' }}
                  size="sm"
                  type="number"
                  defaultValue=""
                  placeholder="Fiyatı"
                  register={register(`tickets.${i}.price`, { required: true, min: 1 })}
                  textAlign="right"
                  prepend={currency}
                />
              </td>
              <td align="right">
                <CommonInput
                  id="ticket-stock"
                  disabled={seated || disabled}
                  size="sm"
                  type="number"
                  placeholder={(seated ? 'Koltuk Seçilmemiş' : 'Stok')}
                  style={{ width: '7rem' }}
                  // value={distribution.reduce((c, s) => c + (s && s?.includes(i)), 0) || ''}
                  {...(() => {
                    if (seated) {
                      const value = distribution.reduce((c, s) => c + (s && s?.includes(i)), 0);
                      // setValue(`tickets.${i}.stock`, value);
                      return ({ value: value || '' });
                    }
                    return ({
                      register: register(`tickets.${i}.stock`, { required: true, min: 0, valueAsNumber: true }),
                    });
                  })()}
                  //
                  textAlign="right"
                  prepend={((stock <= ticket?.sold) || (stock > session?.tickets_stock)) && <i className="fa fa-warning text-danger" />}
                />
              </td>
              <td align="right">
                <CommonInput
                  id="ticket-sold"
                  style={{ width: '5rem' }}
                  disabled
                  size="sm"
                  type="number"
                  value={(ticket?.sold || 0)}
                  placeholder="Yok"
                  textAlign="right"
                />
              </td>
              <td align="right">
                <CommonInput
                  id="ticket-sale"
                  style={{ width: '5rem' }}
                  disabled
                  size="sm"
                  type="number"
                  value={
                    (stock - (ticket?.sold || 0)) > 0
                      ? (stock - (ticket?.sold || 0))
                      : 0
                  }
                  placeholder="Yok"
                  textAlign="right"
                />
              </td>
              {seating && (
                <td align="right">
                  <CommonInput
                    id="ticket-sale"
                    // style={{ width: '5rem' }}
                    size="sm"
                    register={register(`tickets.${i}.seated`, { setValueAs: (v) => Boolean(v) })}
                    type="checkbox"
                    defaultValue={(ticket?.seated)}
                  />
                </td>
              )}
              <td align="right">
                <CommonSelect
                  id="ticket-status"
                  disabled={disabled}
                  size="sm"
                  // disabled={status !== 'active'}
                  placeholder="Lütfen bir durum seçin"
                  defaultValue={ticket.active}
                  register={register(`tickets.${i}.active`)}
                  options={[
                    { value: true, name: strings.active },
                    { value: false, name: strings.inactive },
                  ]}
                />
              </td>
              <td align="center">
                <button
                  id="ticket-delete"
                  disabled={disabled}
                  type="button"
                  className="table-button btn btn-danger"
                  onClick={() => {
                    fieldArray.remove(i);
                    onRemove(i);
                  }}
                >
                  <i className="fa fa-remove" />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    <div className="mr-2 mt-3">
      <button
        id="ticket-add"
        type="button"
        className="btn btn-primary mr-2 float-right"
        style={{ height: 38 }}
        onClick={() => fieldArray.append({
          category: '',
          name: '',
          price: '',
          stock: '',
          active: true,
        })}
      >
        <i className="fa fa-plus mr-2" />
        Yeni bilet türü ekle
      </button>
      {secondaryAction}
    </div>
  </div>
);
export default EventTicketsTable;
