import { useEffect } from 'react';

import { DecodifySearch } from '../../utils/StringUtils';
import { setToken } from '../../libs/storage';
import { LoadingIndicator } from '../../components/utils';

const Authenticate = () => {
  useEffect(() => {
    const { token } = DecodifySearch(window.location.search);
    if (token) { setToken(token); } else window.location.replace('/login');
  }, []);
  return (
    <main className="d-flex justify-content-center p-2">
      <LoadingIndicator />
    </main>
  );
};

export default Authenticate;
