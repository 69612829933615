/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { Step } from 'react-rtg';
import { observer } from 'remx';
import dayjs from 'dayjs';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { MiniEvents } from '../../../components/tables';
import { QuickLine } from '../../../components/charts';

import { Thousandify } from '../../../utils/StringUtils';
import Help from '../../../components/utils/Help';

import { get } from '../../../libs/client';
import stores from '../../../libs/stores';

const plugins = { legend: { display: false }, title: { display: false } };

const NearEvents = ({
  events, monthly, selected, onSelect,
}) => (
  <section className="grid grid-4 grid-1-m">
    <main id="home-near-events" className="main d-flex flex-column fw-100-m">
      <h5 className="mb-2">Yaklaşan 5 Etkinlik</h5>
      <section style={{ overflowY: 'scroll', paddingTop: '0.75rem' }}>
        <MiniEvents selected={selected} events={events} onSelect={onSelect} />
      </section>
    </main>
    <main className="main d-flex flex-column dashboard-table">
      <h5 className="mb-2">{selected ? `${selected.name} Satışları` : 'Yaklaşan Tüm Etkinliklerin Satışları'}</h5>
      <section id="home-monthly" className="position-relative min-height flex-grow-1">
        {monthly?.length > 0
          ? (
            <QuickLine
              options={{ responsive: true, maintainAspectRatio: false, plugins }}
              data={{
                labels: Array.from(Array(31)).map(((_, i) => dayjs.utc().subtract(i, 'days').format('D MMM'))).reverse(),
                datasets: [
                  {
                    label: 'Bilet',
                    lineTension: 0,
                    data: Array.from(Array(31)).map((_, i) => monthly.find((m) => m._id === dayjs.utc().subtract(i, 'days').format('YYYY-MM-DD'))?.count || 0).reverse(),
                    borderColor: '#017bfe',
                  },
                ],
              }}
            />
          )
          : (<CenteredInformation title=" Veri Yok" subtitle="Satışlarınız başladığı zaman burada gözükür." />)}
      </section>
    </main>
  </section>
);

const StatCard = ({ title, id, stats }) => (
  <main id={id} className="main">
    <p>{title || 'N/A'}</p>
    {stats?.sales > 0 ? (
      <h4 className="mb-0">
        <span className="h2 mr-2">{stats?.sales || 0}</span>
        bilet satıldı
      </h4>
    ) : (
      <h4 className="mb-0">
        <span className="h2 mr-2">Hiç</span>
        bilet satılmamış
      </h4>
    )}
    <b className={stats?.revenue > 0 ? 'text-success' : 'text-secondary'}>
      {stats?.revenue > 0 ? `+${Thousandify((stats?.revenue || 0).toFixed(2))} TRY` : 'Bir değişiklik yok'}
    </b>
  </main>
);

const Home = () => {
  const [events, setEvents] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [stats, setStats] = useState(null);
  const [selected, setSelected] = useState('');

  const [help, setHelp] = useState(false);
  stores.setTutorial(() => setHelp(true));

  const GetEvents = () => get(`/events/search?recent=${true}&page=0&limit=${5}`)
    .then(({ data }) => setEvents(data))
    .catch(console.log);

  const GetMonthly = () => get(`/reports/organizer/monthly/events/${selected?._id || ''}`)
    .then(({ data }) => setMonthly(data))
    .catch(console.log);

  const GetStats = () => Promise.all([
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().format('YYYY-MM-DD')}`),
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().startOf('week').format('YYYY-MM-DD')}`),
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().startOf('month').format('YYYY-MM-DD')}`),
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().startOf('month').subtract(3, 'months').format('YYYY-MM-DD')}`),
  ]).then((data) => setStats({
    daily: data[0].data,
    weekly: data[1].data,
    monthly: data[2].data,
    quarterly: data[3].data,
  })).catch(console.log);

  useEffect(() => { GetEvents(); }, []);
  useEffect(() => { GetMonthly(); GetStats(); }, [selected]);
  useEffect(() => { stores.setAppbar({ left: (<h2 className="m-0">{`${stores.getAccount()?.profile?.name} organizatör paneli`}</h2>) }); }, []);

  return (
    events ? (
      <main className="grid">
        {/* HELP START  */}
        <Help open={help} setOpen={setHelp}>
          <Step selector="#home-stat-card-1" placement="bottom">
            <p>Burada bu gece 12&apos;den itibaren yapılan satışlarınız görünür</p>
          </Step>
          <Step selector="#home-stat-card-2" placement="bottom">
            <p>Burada bu hafta pazartesiden beri yapılan satışlarınız görünür</p>
          </Step>
          <Step selector="#home-stat-card-3" placement="bottom">
            <p>Burada ayın 1&apos;inden beri yapılan satışlarınız görünür</p>
          </Step>
          <Step selector="#home-stat-card-4" placement="bottom">
            <p>Burada da bu çeyrek yaptığınız toplam satışlarınız görünür</p>
          </Step>
          <Step selector="#home-near-events" placement="right">
            <p>Bu kısım en yakın tarihte yapılacak 5 etkinliğinizi gösterir</p>
          </Step>
          <Step selector="#home-monthly" placement="left">
            <p>Bu kısım da son 30 günlük periyotta yaptığınız satışları gösterir</p>
          </Step>
        </Help>
        {/* HELP END  */}

        <section className="grid grid-4">
          <StatCard id="home-stat-card-1" title="Bugün" stats={stats?.daily} />
          <StatCard id="home-stat-card-2" title="Bu Hafta" stats={stats?.weekly} />
          <StatCard id="home-stat-card-3" title="Bu Ay" stats={stats?.monthly} />
          <StatCard id="home-stat-card-4" title="Bu Çeyrek" stats={stats?.quarterly} />
        </section>
        <NearEvents {...{ events, selected, monthly }} onSelect={setSelected} />
      </main>
    )
      : <LoadingIndicator />
  );
};

export default observer(Home);
