import { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { Step } from 'react-rtg';
import { observer } from 'remx';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { ProviderTile } from '../../../components/tiles';

import { get } from '../../../libs/client';
import stores from '../../../libs/stores';
import Help from '../../../components/utils/Help';

const Providers = () => {
  const [providers, setProviders] = useState(null);
  const [help, setHelp] = useState(null);
  stores.setTutorial(() => { setHelp(true); });

  const GetProviders = () => get('/organizers/providers/me')
    .then(({ data }) => setProviders(data))
    .catch((err) => console.log(err));

  useEffect(() => {
    GetProviders();
    stores.setAppbar({
      left: (<h2 className="m-0">Ödeme Sağlayıcılarım &amp; Sanal POS&apos;larım</h2>),
      right: (
        <Link to="/providers/new">
          <button type="button" className="btn btn-primary" id="add-provider">
            <i className="fa fa-plus mr-2" />
            ÖDEME SAĞLAYICI EKLE
          </button>
        </Link>
      ),
    });
  }, []);
  if (providers) {
    if (providers.length) {
      return (
        <main className="grid">
          {/* HELP START  */}
          <Help open={help} setOpen={setHelp}>
            <Step selector="#provider" placement="right">
              <p>Bu kısımda ödeme sağlayıclarınızı görebilir ve düzenleyebilirsiniz</p>
            </Step>
            <Step selector="#add-provider" placement="bottom">
              <p>Satış yapmak için en az bir ödeme sağlayıcısına ihtiyacınız var</p>
            </Step>
            <Step placement="center">
              <p>Farklı etkinlikleriniz için birden fazla ödeme sağlayıcısı ekleyebilirsiniz</p>
            </Step>

          </Help>
          {/* HELP END  */}
          <section className="grid grid-3">
            {providers.map((provider) => <ProviderTile {...{ provider }} />)}
          </section>
        </main>
      );
    } return (
      <CenteredInformation
        title="Ödeme sağlayıcısı yok"
        subtitle="Satış yapmak için sahibi olduğunuz bir ödeme sağlayıcısı eklemeniz gerekiyor."
        fixed
      />
    );
  } return <LoadingIndicator />;
};

export default observer(Providers);
