/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'wouter';
import { Step } from 'react-rtg';
import { observer } from 'remx';

import { CommonInput } from '../../../components/widgets';
import { LoadingIndicator } from '../../../components/utils';

import { get, post } from '../../../libs/client';
import stores from '../../../libs/stores';

import Help from '../../../components/utils/Help';

const Card = ({ params }) => {
  const { register, handleSubmit } = useForm({ defaultValues: { provider: 'paywall' } });
  const [provider, setProvider] = useState(null);
  const [, setLocation] = useLocation();

  const [help, setHelp] = useState(null);
  stores.setTutorial(() => { setHelp(true); });

  const GetProvider = () => get('/organizers/providers/me')
    .then(({ data }) => setProvider(data[0]))
    .catch((err) => console.log(err));

  const SaveCard = (data) => {
    const form = data;
    form.RelationalId1 = stores.getAccount().profile._id;
    form.RelationalId2 = stores.getAccount()._id;
    form.RelationalId3 = '';
    // post('/organizers/providers/me', form)
    // .then(() => { alert('Ödeme sağlayıcı oluşturuldu'); setLocation('/providers'); })
    // .catch(() => alert('Ödeme sağlayıcı oluşturulurken bir hata oldu.'))
    console.log(form);
    fetch('https://dev-payment-private-api.itspaywall.com/paywall/card', {
      method: 'POST',
      headers: {
        apikeyprivate: provider.credentials.paywallPrivateKey,
        apiclientprivate: provider.credentials.paywallPrivateClient,
      },
      body: JSON.stringify(form),
    });
  };

  useEffect(() => { GetProvider(); }, []);
  useEffect(() => stores.setAppbar({
    left: (<h2 className="m-0">Yeni Kart Ekle</h2>),
    right: (
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleSubmit(SaveCard)}
      >
        <i className="fa fa-save mr-2" />
        {params.id === 'new' ? 'KART KAYDET' : 'KAYDET'}
      </button>
    ),
  }), [provider]);

  return (
    (provider || params.id === 'new') ? (
      <main className="grid">
        {/* HELP START  */}
        <Help open={help} setOpen={setHelp}>
          <Step selector="#provider-name" placement="bottom">
            <p>Buradan, ödeme sağlayıcınıza akılda kalıcı bir isim verin</p>
          </Step>
          <Step selector="#provider-bank" placement="bottom">
            <p>Kullandığınız banka ismini girin</p>
          </Step>
          <Step selector="#provider-type" placement="bottom">
            <p>Ödeme sağlayıcı hizmeti aldığınız firmayı seçin</p>
          </Step>
          <Step selector="#provider-form" placement="top">
            <p>Ve ödeme sağlayıcınızdan aldığınız bilgileri kontrol ederek buraya girin</p>
          </Step>
          <Step placement="center">
            <p>Her şey doğru ise, satış yapmaya hazırsınız!</p>
          </Step>
        </Help>
        {/* HELP END  */}
        <form>
          <h4> Kart Bilgileri</h4>
          <section className="grid">
            <CommonInput
              id="card-name"
              label="Kart Adı"
              register={register('Card.Nickname', { required: true })}
              placeholder="Sanal Kartım"
              defaultValue="TEST KARTI"
            />
            <CommonInput
              id="card-holder"
              label="Kart Sahibinin Adi"
              register={register('Card.HolderName', { required: true })}
              placeholder="Ahmet Yımaz"
              defaultValue="ALP SAVRUM"
            />
            <section className="grid grid-2">
              <CommonInput
                id="card-number"
                label="Kart Numarası"
                register={register('Card.Number', { required: true })}
                placeholder="5500 0001 0001 0001"
                defaultValue="5528790000000008"
              />
              <section className="grid grid-2">
                <CommonInput
                  id="card-name"
                  label="Son Kullanma Ayı"
                  register={register('Card.Month', { required: true })}
                  placeholder="04"
                  defaultValue="12"
                />
                <CommonInput
                  id="card-holder"
                  label="Son Kullanma Yılı"
                  register={register('Card.Year', { required: true })}
                  placeholder="2029"
                  defaultValue="2030"
                />
              </section>
            </section>
          </section>
        </form>
        <p>
          ES Events, kart saklama çözüm ortağı olarak
          {' '}
          <img style={{ margin: '0 0.5rem' }} src="https://www.itspaywall.com/assets/img/logos/paywall-logo-3.png" height={24} alt="Paywall" />
          {' '}
          ile çalışmaktadır. Detaylı bilgi almak için
          {' '}
          <a target="_blank" href="https://www.paywall.one/#/" rel="noreferrer">Paywall</a>
          {' '}
          web sitesini ziyaret edin.
        </p>
      </main>
    ) : <LoadingIndicator />
  );
};

export default observer(Card);
