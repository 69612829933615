/* eslint-disable no-underscore-dangle */
import { Link } from 'wouter';

import PAYMENT_PROVIDER_LOGOS from '../../constants/payment-providers.json';

const ProviderTile = ({ provider }) => (
  <Link to={`/providers/${provider._id}`}>
    <main className="main pointer d-flex flex-column" id="provider">
      <h4 className="text-2-line mb-2">{provider?.name}</h4>
      <div className="d-flex flex-row align-items-end flex-grow-1">
        <h6 className="flex-grow-1">{provider?.bank}</h6>
        <img
          width={100}
          className="mb-2 float-right"
          alt={provider?.provider}
          src={PAYMENT_PROVIDER_LOGOS.find(({ value }) => value === provider.provider)?.logo}
        />
      </div>
    </main>
  </Link>
);

export default ProviderTile;
