/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */

const SaleTicketsTable = ({ data, currency, onRefund }) => (
  <main className="grid">
    <h4>Satılan Biletler</h4>
    <table className="w-100">
      <thead>
        <tr>
          <th>#</th>
          <th>Adı</th>
          <th className="text-right">Fiyatı</th>
          <th className="text-right">Düzenleyen</th>
          <th className="text-center">Ödeme Durumu</th>
          <th className="text-left">Satış Numarası</th>
          <th className="text-center">İade Edilmiş</th>
        </tr>
      </thead>
      <tbody>
        {data.map((ticket, i) => (
          <tr>
            <td>{1 + i}</td>
            <td>{ticket.name}</td>
            <td className="text-right">{`${ticket.subtotal} ${currency}`}</td>
            <td className="text-right">{ticket.issuedBy ? `${ticket.issuedBy?.firstName} ${ticket.issuedBy?.lastName}` : 'Yok'}</td>
            <td className="text-center">{ticket.status}</td>
            <td className="text-left">{ticket.txn}</td>
            <td className="text-center">
              <button
                disabled={ticket.status === 'refund'}
                id="ticket-delete"
                type="button"
                className={`table-button btn btn-${ticket.status === 'refund' ? 'success' : 'danger'}`}
                onClick={() => onRefund(ticket._id)}
              >
                <i className={`fa fa-${ticket.status === 'refund' ? 'check' : 'remove'}`} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </main>
);
export default SaleTicketsTable;
