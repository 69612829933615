/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { Link } from 'wouter';

const EventsTable = ({
  data, total, index, exclusiveQueries = '', deletable, pager, onClickActive, onClickDelete,
}) => (
  <main id="table-event" className="main table-responsive">
    <h4 className="flex-grow-1 flex-grow-1 mb-3">{data?.length > 0 ? `Toplam ${total} etkinlik var` : 'Hiç etkinlik yok'}</h4>
    <table className="table">
      <thead>
        <tr>
          <th scope="col" className="text-center">#</th>
          <th scope="col">Etkinlik Adı</th>
          <th scope="col">Organizatör</th>
          <th scope="col">Tarih Aralığı</th>
          {/* <th scope="col">Fiyat Aralığı</th> */}
          <th scope="col">Mekanlar</th>
          <th scope="col">Seanslar</th>
          {!deletable && <th scope="col">Aktif Mi?</th>}
          {deletable && <th scope="col">Sil</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((entity, i) => (
          <tr id="table-event-row" key={entity._id}>
            <td><div>{index + i + 1}</div></td>
            <td>
              <div id="table-event-link">
                <img className="mr-2 rounded" alt="logo" src={entity?.image} width={64} height={64} />
                <Link to={`/events/${entity?._id}${exclusiveQueries}`}>{entity?.name}</Link>
              </div>
            </td>
            <td><div><Link to={`/organizers/${entity?.owner?._id}`}>{entity?.owner?.name}</Link></div></td>
            <td>
              <div>
                {entity?.dates?.min
                  ? (
                    (entity?.dates?.min !== entity?.dates?.max)
                      ? (`${dayjs.utc(entity?.dates?.min).format('DD MMM YYYY')} - ${dayjs.utc(entity?.dates?.max).format('DD MMM YYYY')}`)
                      : dayjs.utc(entity?.dates?.min).format('DD MMM YYYY')
                  )
                  : <p>Satış Bilgisi Yok</p>}
              </div>
            </td>
            {/* <td>
              <div>
                {entity?.tickets?.min
                  ? (
                    (entity?.tickets?.min !== entity?.tickets?.max)
                      ? <p className="m-0">{`${entity?.tickets?.min} ${entity?.currency} - ${entity?.tickets?.max} ${entity?.currency}`}</p>
                      : <p className="m-0">{`${entity?.tickets?.min} ${entity?.currency}`}</p>
                  )
                  : <p>Satış Bilgisi Yok</p>}
              </div>

            </td> */}
            {/* <td><div>{dayjs.utc(entity?.createdAt).format('DD MMM YYYY, HH:mm')}</div></td> */}
            <td><div>{`${entity?.sales || 0} Mekan`}</div></td>
            <td><div>{`${entity?.sessions || 0} Seans`}</div></td>
            {!deletable && (
              <td>
                <div>
                  <button
                    type="button"
                    onClick={() => onClickActive(entity)}
                    className={`w-100 btn btn-sm btn-${entity?.active ? 'success' : 'danger'}`}
                  >
                    {entity?.active ? 'Evet' : 'Hayır'}
                  </button>
                </div>
              </td>
            )}
            {deletable && (
              <td>
                <div>
                  <button
                    type="button"
                    onClick={() => onClickDelete(entity)}
                    className="w-100 btn btn-sm btn-danger"
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
    <div className="float-right">{pager}</div>
  </main>
);

export default EventsTable;
