import { useEffect } from 'react';

import { post } from '../../libs/client';
import { setToken } from '../../libs/storage';
import { LoadingIndicator } from '../../components/utils';

import strings from '../../localization';

const AttemptLogin = async (email, _data) => {
  const code = window.prompt(`Lütfen tek kullanımlık kodunuzu girin (${_data?.code})`);
  if (code) {
    return post('/auth/login', { email, code })
      .then(({ data }) => { setToken(data.token); })
      .catch(() => { alert('Girdiğiniz kod yanlış'); AttemptLogin(email); });
  }
  return window.location.reload();
};
const RequestCode = async () => {
  const email = window.prompt('Lütfen ES Events organizatör hesabınızın e-posta adresini girin');
  post('/auth/request', { type: 'organizer', email })
    .then(({ data }) => { AttemptLogin(email, data); })
    .catch(({ message }) => { alert(strings[message]); RequestCode(); });
};

const Login = () => {
  useEffect(() => RequestCode(), []);

  return (
    <LoadingIndicator />
  );
};

export default Login;
