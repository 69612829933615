/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { CenteredInformation } from '../utils';

const MiniEventsTable = ({ events, selected, onSelect }) => (
  <div className="mini-events min-height">
    {events.length > 0
      ? events.map((event) => (
        <div key={event._id} className="d-flex flex-direction-row align-items-center mb-2">
          <div className="position-relative mr-2">
            <img
              className="rounded"
              style={{ objectFit: 'cover', filter: 'brightness(50%)', backgroundColor: 'white' }}
              alt="event logo"
              src={event?.image}
              width={64}
              height={64}
            />
            <h6 className="position-absolute center text-light text-center m-0">{dayjs.utc(event?.dates?.min).format('D\nMMM')}</h6>
          </div>
          <button
            onClick={() => (selected !== event ? onSelect(event) : onSelect(''))}
            type="button"
            style={{ flex: 1, height: 64 }}
            className={`btn ${selected?._id === event?._id ? 'btn-primary' : ''} text-left`}
          >
            <p className="text-2-line  w-100">
              {event?.name}
            </p>
          </button>
        </div>
      ))
      : (
        <CenteredInformation
          style={{ marginTop: '1rem' }}
          title="Veri Yok"
          subtitle="Etkinlik açılmamış."
        />
      )}
  </div>
);

export default MiniEventsTable;
