/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Step } from 'react-rtg';
import { observer } from 'remx';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { CommonInput, CommonSelect } from '../../../components/widgets';
import { SalesEventTable, SalesTable } from '../../../components/tables';
import { get, put } from '../../../libs/client';
import { DecodifySearch, QuerifySearch } from '../../../utils/StringUtils';
import stores from '../../../libs/stores';
import Help from '../../../components/utils/Help';
import OrganizerPaymentDialog from '../../../components/utils/OrganizerPaymentDialog';
// import strings from '../../../localization';

const PAGINATION_LIMIT = 15;

const SaleSearch = ({ disabled, onSearch, onClear }) => {
  const [clear, setClear] = useState(false);
  const { register, reset, handleSubmit } = useForm();

  return (
    <div className="d-flex align-items-end">
      <CommonInput
        id="input-reference"
        className="fl-1 mr-2"
        placeholder="Bilet referansı ile ara"
        register={register('reference')}
        disabled={disabled}
      />
      <button
        type="button"
        className="btn btn-primary btn-normal"
        onClick={() => { setClear(true); handleSubmit(onSearch)(); }}
        disabled={disabled}
      >
        <i className="fa fa-search mr-2" />
        ARA
      </button>
      {clear && (
        <button
          type="button"
          className="btn btn-danger btn-normal ml-2"
          onClick={() => { setClear(false); reset({ reference: '' }); onClear(); }}
          disabled={disabled}
        >
          <i className="fa fa-trash" />
        </button>
      )}
    </div>
  );
};

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [search, setSearch] = useState(null);

  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);

  const [payment, setPayment] = useState(null);

  const [help, setHelp] = useState(false);
  stores.setTutorial(() => { setHelp(true); });

  const GetSales = () => get(`/sales/events?page=${page}&sort=desc&limit=${PAGINATION_LIMIT}&${QuerifySearch(DecodifySearch(window.location.search))}`)
    .then(({ data, total }) => { setSales(data); setPages(total); }).catch(console.log);

  const SearchSales = (query) => query && get(`/sales?page=0&limit=10&${query}`)
    .then(({ data, total }) => { setSales(data); setPages(total); }).catch(console.log);

  const CreatePayment = () => null;

  useEffect(() => { GetSales(); }, [page]);

  useEffect(() => {
    stores.setAppbar({
      left: (
        <h2 className="m-0">Etkinlik Ödemeleri</h2>
      ),
      right: (
        <SaleSearch
          onSearch={(query) => SearchSales(QuerifySearch(query))}
          onClear={() => setSearch(null)}
          disabled={sales?.length < 1}
        />
      ),
    });
  }, [sales]);

  return (
    sales
      ? sales?.length > 0 ? (
        <main className="grid">

          {/* HELP START  */}
          {/* <Help open={help} setOpen={setHelp}>
            <Step selector="#table-sales" placement="left">
              <p>Bu tabloda etkinliklerinizden satın alınan biletleri görüntülersiniz</p>
            </Step>
            <Step selector="#input-reference" placement="bottom">
              <p>Buradan satış refereans numarası ile arayabilirsiniz</p>
            </Step>
            <Step selector="#event-sale-row" placement="top">
              <p>Bu satırda toplam sepet bedelini ve satışı görüntülersiniz</p>
            </Step>
            <Step selector="#event-sale-name" placement="top">
              <p>Bu linkten etkinlik detaylarına ulaşabilirsiniz</p>
            </Step>
            <Step selector="#event-sale-reference" placement="top">
              <p>Bu linkten satış detaylarına ulaşabilirsiniz</p>
            </Step>
            <Step selector="#event-sale-gateway" placement="top">
              <p>
                Bu linkten de ödeme arayüzünüzden iptal
                ve iade gibi işlemleri gerçekleştirebilirsiniz
              </p>
            </Step>
          </Help> */}
          {/* HELP END  */}

          <SalesEventTable
            sales={search || sales}
            pager={pages > PAGINATION_LIMIT && (
            <p className="d-flex align-items-center mr-2">
              sayfa
              <CommonSelect
                className="mx-2"
                value={page + 1}
                placeholder={1}
                onChange={({ target }) => setPage(target.value)}
                options={Array.from(Array(Math.floor(pages / PAGINATION_LIMIT)))
                  .map((_, i) => ({ name: i + 2, value: i + 1 }))}
              />
              {`/ ${Math.floor(pages / PAGINATION_LIMIT) + 1}`}
            </p>
            )}
            onPayment={setPayment}
          />
          {payment && (
            <OrganizerPaymentDialog
              sale={payment}
              onClose={() => setPayment(null)}
              onSuccess={() => {
                alert('Ödeme Başarılı\nÖdemenizin onaylanması bir kaç dakika sürebilir, lütfen sayfayı tazeleyin.');
                GetSales();
                setPayment(null);
              }}
              onFail={() => {
                alert('Ödeme Başarısız');
                setPayment(null);
              }}
            />
          )}
        </main>
      ) : (
        <CenteredInformation
          title="Bekleyen ödemeniz yok"
          subtitle="Etkinliklerinizi satışa açmak için yapmanız gereken ödemeler burada gözükür."
          fixed
        />
      )
      : <LoadingIndicator />
  );
};

export default observer(Sales);
