import { Switch, Route, Redirect } from 'wouter';

import Login from './Login';
import Oauth from './Oauth';

const routes = [
  { path: '/login', component: Login },
  { path: '/oauth', component: Oauth },
];

const Auth = () => (
  <main className="d-flex justify-content-center p-2">
    <Switch>
      {routes.map((r) => <Route key={r.path} {...r} />)}
      <Redirect to="/login" />
    </Switch>
  </main>
);

export default Auth;
