/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { post } from '../../libs/client';
import { CommonInput } from '../widgets';

function PopupWindow(url, title, w, h) {
  const left = (window.screen.width / 2) - (w / 2);
  const top = (window.screen.height / 2) - (h / 2);
  return window.open(url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`);
}

const OrganizerPaymentDialog = ({
  sale, onClose, onSuccess, onFail,
}) => {
  const [link, setLink] = useState(null);
  const popup = useRef(null);

  const CreatePaymentLink = () => {
    post('/sales/events/purchase', { sale: sale._id })
      .then(({ data }) => {
        setLink(data);
      })
      .catch(() => {
        alert('Ödeme linki oluşturulamadı');
        onClose();
      });
  };

  const MessageHandler = (e) => {
    console.log(e);
    if (e.data === 'success') {
      // onClose();
      onSuccess();
    } if (e.data === 'fail') {
      // onClose();
      onFail();
    }
  };

  useEffect(() => {
    CreatePaymentLink();
  }, []);

  useEffect(() => {
    window.addEventListener('message', MessageHandler);
    return () => { window.removeEventListener('message', MessageHandler); };
  }, []);

  useEffect(() => {
    if (link) {
      popup.current = PopupWindow(link, 'ES Events Ödeme Sistemi', 1024, 768);
      const timer = setInterval(() => {
        if (popup.current.closed) {
          popup.current = null;
          clearInterval(timer);
          setLink(null);
          onClose();
        }
      }, 1000);
    }
  }, [link]);

  return (
    // <div className="modal" style={{ display: 'block', background: '#000000AA' }}>
    //   <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '50vw' }}>
    //     <div className="modal-content" style={{ height: '80vh' }}>
    //       <div className="modal-header">
    //         <h5 className="modal-title">Etkinlik Ödemesi</h5>
    //         <button type="button" className="close" onClick={onClose}>
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body p-1">
    //         <iframe
    //           title="Payment Screen"
    //           src={link}
    //           className="border-0 w-100 h-100"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    null
  );
};

export default OrganizerPaymentDialog;
