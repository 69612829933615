/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */

import { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  ArtistInput, CommonInput, CommonSelect, ImageInput,
} from '../../../../components/widgets';

const maxLength = 140;

const EventBasicInfo = ({
  params, show, register, event, control, categories, watch,
}) => {
  const [image, qr, desc, notes] = watch(['image', 'qr', 'desc', 'notes']);
  const [unlockNotifications, setUnlockNotifications] = useState(false);

  return (
    <section className="grid" style={{ display: show ? 'grid' : 'none' }}>
      <form id="event-detail-basic">
        <h4>Temel Bilgiler</h4>
        <section className="grid grid-2">
          <CommonInput
            label="Etkinlik Adı"
            placeholder="eg: Sunset Events"
            register={register('name', { required: true })}
            defaultValue={event?.name}
          />
          <CommonSelect
            label="Etkinlik Kategorisi"
            placeholder="Lütfen bir kategori seçin"
            register={register('category', { required: true })}
            defaultValue={event?.category}
            options={categories}
            domain="category"
          />
        </section>
      </form>
      <form id="event-detail-desc">
        <h4>Etkinlik Açıklamaları</h4>
        <section className="grid grid-2">
          <CommonInput
            label="Türkçe Açıklama"
            register={register('desc.tr', { required: true, maxLength: 300 })}
            defaultValue={event?.desc?.tr}
            length={desc?.tr?.length}
            limit={300}
            multiline
          />
          <CommonInput
            label="İngilizce Açıklama"
            register={register('desc.en', { maxLength: 300 })}
            defaultValue={event?.desc?.en}
            length={desc?.en?.length}
            limit={300}
            multiline
          />
        </section>
      </form>
      <form>
        <h4>Etkinlik Görselleri</h4>
        <section className="grid grid-2">
          <ImageInput
            id="event-detail-image"
            register={register('image', {
              required: true,
              validate: (value) => {
                if (value.includes('base64')) {
                  alert('Resim linki base64 olamaz! Lütfen başka bir link deneyin.');
                  return false;
                } if (value.includes('http://')) {
                  alert('Resim linki http olamaz! Lütfen https ile başlayan bir link deneyin.');
                  return false;
                } return true;
              },
            })}
            defaultValue={event?.image}
            watch={image}
            resolution="1024x1024"
            ratio="1:1"
            placeholder="Uygulamada, ana sayfada gösterilecek olan görsel"
            label="Ana Etkinlik Görseli"
          />
          <ImageInput
            id="event-detail-qr"
            register={register('qr', { required: true })}
            defaultValue={event?.qr}
            watch={qr}
            resolution="512x512"
            ratio="1:1"
            placeholder="Uygulamada, biletlerin üzerinde gösterilecek olan görsel"
            label="QR Kod Görseli"
          />
        </section>
      </form>
      <form id="event-detail-artists">
        <h4>Etkinlik Sanatçıları</h4>
        <Controller
          control={control}
          name="artists"
          render={({ field: { onChange } }) => (
            <ArtistInput data={event?.artists} onChange={onChange} />
          )}
        />
      </form>
      {params.id !== 'new' && (
      <form id="event-detail-alerts">
        <h4 className="d-flex align-items-center">
          <span className="flex-grow-1">Etkinlik Bildirileri</span>
          <button
            type="button"
            className="btn h6 ml-2 text-danger"
            onClick={() => {
              if (window.confirm('Bu alan kullanıcıları erteleme, iptal gibi kritik konularda bilgilendirmek için kullanılmalıdır.')) {
                setUnlockNotifications(!unlockNotifications);
              }
            }}
          >
            <i className="fa fa-warning mr-2" />
            (Sadece etkinlik hakkında acil bilgiler için kullanın!)
            <i className="fa fa-warning ml-2" />
          </button>
        </h4>
        <section className="grid grid-2">
          <CommonInput
            disabled={!unlockNotifications}
            label="Türkçe Bildiriler"
            register={register('notes.tr', { maxLength })}
            defaultValue={event?.notes?.tr}
            length={notes?.en?.length}
            limit={maxLength}
            multiline
          />
          <CommonInput
            disabled={!unlockNotifications}
            label="İngilizce Bildirileri"
            register={register('notes.en', { maxLength })}
            defaultValue={event?.notes?.en}
            length={notes?.en?.length}
            limit={maxLength}
            multiline
          />
        </section>
      </form>
      )}
    </section>
  );
};

export default EventBasicInfo;
