import { CommonInput } from '../../../../components/widgets';

const WeepayForm = ({ register, provider }) => (
  <section className="grid">
    <CommonInput
      label="Weepay Bayi ID"
      register={register('credentials.weepayBayiId', { required: true })}
      defaultValue={provider?.credentials?.weepayBayiId}
    />
    <section className="grid grid-2">
      <CommonInput
        label="Weepay API Key"
        register={register('credentials.weepayApiKey', { required: true })}
        defaultValue={provider?.credentials?.weepayApiKey}
      />
      <CommonInput
        label="Weepay Secret Key"
        register={register('credentials.weepaySecret', { required: true })}
        defaultValue={provider?.credentials?.weepaySecret}
      />
    </section>
  </section>
);

export default WeepayForm;
