/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// import { EventTicketsTable } from '../../../../components/tables';

import { EventTicketsTable } from '../../../../../components/tables';
import { SeatingEngine } from '../../../../../components/utils';
import { CommonInput, CommonSelect } from '../../../../../components/widgets';
import { post, put } from '../../../../../libs/client';
import { DecodifySearch } from '../../../../../utils/StringUtils';
import { getToken } from '../../../../../libs/storage';

const Session = ({
  sale, params, session, plans, disabled, onRemove, help,
}) => {
  const {
    register, control, watch, reset, setValue, handleSubmit,
  } = useForm();

  const fieldArray = useFieldArray({ control, name: 'tickets' });

  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(session.active);
  const [start, end, tickets, seating, distribution] = [
    watch('start'), watch('end'), watch('tickets'),
    watch('seating_plan'),
    watch('seating_distribution'),
  ];
  const [showSeating, setShowSeating] = useState(false);

  useEffect(() => { setOpen(true); }, [help]);

  const CheckStock = (form) => {
    if ((Math.max(...form.tickets.map((t) => Number(t.stock - (t?.sold || 0)))) <= Number(form.tickets_stock - (data?.sold || 0)))) {
      return true;
    } return alert('Bilet kategorilerinin stoğu, kalan bilet sayısından fazla olamaz.');
  };
  const CheckSoldOut = (form) => {
    let hasSoldOuts = false;
    form.tickets.forEach((t, ti) => { hasSoldOuts = hasSoldOuts || (Number(t?.stock) <= Number(session?.tickets[ti]?.sold)); });
    if (hasSoldOuts) {
      if (window.confirm('Stokları tükenmiş biletler var, kaydetmek istediğinize emin misiniz?')) {
        return CheckStock(form);
      } return false;
    } return CheckStock(form);
  };

  const CreateSession = (form) => {
    if (form?.tickets?.length > 0) {
      if (CheckSoldOut(form)) {
        // Assign if seated param to control
        // form.tickets = form.tickets.map((t, i) => ({ ...t, seated: (distribution.reduce((c, s) => c + (s && s?.includes(i)), 0) > 0) }));
        post(`/events/${params.eid}/sales/${params.id}/sessions`, form)
          .then((instance) => { alert('Seans oluşturuldu.'); setData(instance.data); })
          .catch(() => alert('Seans oluşturulurken bir hata oldu.'));
      }
    } else { alert('En az bir bilet kategorisi girmeniz gerekiyor'); }
  };

  const UpdateSession = (form) => {
    if (CheckSoldOut(form)) {
      // Assign if seated param to control
      // form.tickets = form.tickets.map((t, i) => ({ ...t, seated: (distribution.reduce((c, s) => c + (s && s?.includes(i)), 0) > 0) }));
      put(`/events/${params.eid}/sales/${params.id}/sessions/${data._id}`, form)
        .then(() => alert('Seans güncellendi.'))
        .catch(() => alert('Seans güncellenirken bir hata oldu.'));
    }
  };

  // const CreateSession = (form) => console.log(form);
  // const UpdateSession = (form) => { console.log(form); };

  const SwitchSessionStatus = () => {
    put(`/events/${params.eid}/sales/${params.id}/sessions/${session._id}`, { active: !active })
      .then((res) => { alert('Seans güncellendi.'); setActive(res.data.active); })
      .catch(() => alert('Seans güncellenirken bir hata oldu.'));
  };

  // Kind of wacky, but works as intended.
  const RemoveSelectedSeats = (i) => {
    const _distribution = distribution.map((dist) => dist.filter((assignment) => assignment !== i));
    setValue('seating_distribution', _distribution);
  };

  useEffect(() => {
    if (session) {
      reset({
        seating_plan: session?.seating_plan,
        seating_distribution: session?.seating_distribution,
        tickets: session?.tickets,
      });
      setOpen((DecodifySearch(window.location.search)?.session === session?._id) || (session._id.includes('new')));
      setData(session);
    }
  }, [session]);

  useEffect(() => {
    setActive(data?.active);
  }, [data]);

  useEffect(() => {
    setValue('seating_plan', session?.seating_plan?._id);
  }, [plans]);

  useEffect(() => data?._id?.includes('new') && setValue('seating_distribution', []), [seating]);

  return (
    data && (
      <form id="basic-info">
        <details open={help || open}>
          <summary className="d-flex align-items-center">
            <h4 className="flex-grow-1 m-0">
              {`${(() => {
                if (start) {
                  return dayjs.utc(start).format('D MMMM, HH:mm');
                }
                if (data?.start) {
                  return dayjs.utc(data?.start).format('D MMMM, HH:mm');
                } return 'Başlangıç Tarihi Seçin';
              })()} - ${(() => {
                if (end) {
                  return dayjs.utc(end).format('D MMMM, HH:mm');
                } if (data?.end) {
                  return dayjs.utc(data?.end).format('D MMMM, HH:mm');
                } return 'Bitiş Tarihi Seçin';
              })()}`}
            </h4>
            {data?.sold > 0 && (
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => {
                  window.open(`https://api.hibilet.com/api/reports/sales/tickets?session=${data._id}&toExcel=true&token=${getToken()}`);
                }}
              >
                <i className="fa fa-list mr-2" />
                KATILIMCI LİSTESİ
              </button>
            )}
            {data._id.includes('new') && (
              <button
                type="button"
                className="btn btn-danger mr-2"
                onClick={onRemove}
              >
                <i className="fa fa-trash mr-2" />
                SEANSI SİL
              </button>
            )}
            {!data._id.includes('new') && (
              <button
                type="button"
                className={`btn btn-${active ? 'danger' : 'success'} mr-2`}
                onClick={() => SwitchSessionStatus()}
              >
                <i className={`fa fa-${active ? 'ban' : 'check'} mr-2`} />
                {active ? 'SEANSI GİZLE' : 'SEANSI LİSTELE'}
              </button>
            )}
            <button
              id="create-session"
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit(data._id.includes('new') ? CreateSession : UpdateSession, (e) => {
                console.log(e);
                if (e.start) { return alert('Lütfen başlangıç tarihini kontrol edin'); }
                if (e.end) { return alert('Lütfen bitiş tarihini kontrol edin'); }
                return null;
              })}
            >
              <i className="fa fa-save mr-2" />
              {data._id.includes('new') ? 'KAYDET' : 'GÜNCELLE'}
            </button>
          </summary>
          <section className="grid grid-3 mt-2 mb-3">
            {(() => {
              const min = dayjs.utc().startOf('day').add(7, 'days').format('YYYY-MM-DDTHH:mm');
              return (
                <>
                  <CommonInput
                    id="session-start"
                    // disabled={sale?.vendor_confirmation === 'confirmed' && sale?.venue_confirmation === 'confirmed'}
                    label="Seans Başlangıç Tarihi"
                    defaultValue={(() => {
                      if (data?.start) {
                        return dayjs.utc(data?.start).format('YYYY-MM-DDTHH:mm');
                      } return null;
                    })()}
                    register={register('start', {
                      validate: (value) => dayjs.utc(value).isAfter(dayjs.utc()),
                    })}
                    min={min}
                    type="datetime-local"
                    onChange={({ target }) => reset({ end: dayjs.utc(target.value).add(4, 'hours').format('YYYY-MM-DDTHH:mm') })}
                  />
                  <CommonInput
                    id="session-end"
                    // disabled={sale?.vendor_confirmation === 'confirmed' && sale?.venue_confirmation === 'confirmed'}
                    label="Seans Bitiş Tarihi"
                    register={register('end', {
                      validate: (value) => dayjs.utc(value).isAfter(dayjs.utc(start)),
                    })}
                    defaultValue={(() => {
                      if (data?.end) {
                        return dayjs.utc(data?.end).format('YYYY-MM-DDTHH:mm');
                      } return null;
                    })()}
                    min={(() => {
                      if (end) {
                        return dayjs.utc(start).format('YYYY-MM-DDTHH:mm');
                      } return dayjs.utc(min).format('YYYY-MM-DDTHH:mm');
                    })()}
                    type="datetime-local"
                  />
                </>
              );
            })()}
            <CommonSelect
              label="Oturma Planı"
              id="session-seating"
              placeholder={(() => {
                if (!data._id.includes('new')
                && sale?.vendor_confirmation !== 'pending'
                && sale?.venue_confirmation !== 'pending') {
                  return data?.seating_plan?.name || 'Ayakta Etkinlik';
                }
                return 'Ayakta Etkinlik';
              })()}
              options={plans.map(({ _id, name }) => ({ value: _id, name }))}
              disabled={
                // (fieldArray.fields.length > 0) ||
                (!data._id.includes('new')
                && sale?.vendor_confirmation !== 'pending'
                && sale?.venue_confirmation !== 'pending')
              }
              register={register('seating_plan')}
            />
            <CommonInput
              id="session-stock"
              label="Toplam Bilet Adedi"
              placeholder="örn: 100"
              type="number"
              disabled={sale?.vendor_confirmation === 'confirmed' && sale?.venue_confirmation === 'confirmed'}
              defaultValue={data?.tickets_stock}
              register={register('tickets_stock', { required: true })}
            />
            <CommonInput
              id="session-sold"
              label="Satılan Bilet Adedi"
              placeholder="Biletleriniz satılınca güncellenir"
              disabled
              defaultValue={data?.sold || ''}
            />
            <CommonInput
              id="session-left"
              label="Kalan Bilet Adedi"
              placeholder="Biletleriniz satılınca güncellenir"
              defaultValue={data?.sold ? (data?.tickets_stock - data?.sold) : ''}
              disabled
            />
          </section>
          <EventTicketsTable
            fieldArray={fieldArray}
            session={session}
            seating={seating}
            distribution={distribution}
            register={register}
            setValue={setValue}
            onRemove={RemoveSelectedSeats}
            watch={watch}
            secondaryAction={seating && plans && fieldArray.fields.length > 0 && (
              <button
                id="select-seat"
                type="button"
                className="btn btn-primary mr-2 float-right d-flex align-items-center"
                style={{ height: 38 }}
                onClick={() => setShowSeating(true)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2" viewBox="0 0 16 16">
                  <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5V5h4V1H1.5zM5 6H1v4h4V6zm1 4h4V6H6v4zm-1 1H1v3.5a.5.5 0 0 0 .5.5H5v-4zm1 0v4h4v-4H6zm5 0v4h3.5a.5.5 0 0 0 .5-.5V11h-4zm0-1h4V6h-4v4zm0-5h4V1.5a.5.5 0 0 0-.5-.5H11v4zm-1 0V1H6v4h4z" />
                </svg>
                Koltukları Seç
              </button>
            )}
          />
          {showSeating && (
            <Controller
              control={control}
              name="seating_distribution"
              render={({ field: { onChange, value } }) => (
                <SeatingEngine
                  session={session}
                  params={params}
                  tickets={tickets.filter((t) => !t.unseated)}
                  value={value}
                  plan={seating || session?.seating_plan}
                  onClose={() => setShowSeating(false)}
                  onSelect={(dist) => {
                    if (seating) {
                      tickets.forEach((t, i) => {
                        setValue(`tickets.${i}.stock`, dist.reduce((c, s) => c + (s && s?.includes(i)), 0));
                      });
                    }
                    onChange(dist);
                  }}
                />
              )}
            />
          )}
        </details>
      </form>
    )
  );
};

export default Session;
