/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { get, put } from '../../libs/client';
import { ReferencesTable } from '../tables';
import { CommonInput } from '../widgets';
import strings from '../../localization';

const Account = ({ id = 'new', register, noReferences }) => {
  const [account, setAccount] = useState(null);

  const GetAccount = () => {
    get('/accounts/me')
      .then(({ data }) => setAccount(data))
      .catch(console.log);
  };

  const UpdateAccount = (form) => {
    put('/accounts/me', form)
      .then(() => { alert('Hesap bilgileri kaydedildi'); GetAccount(); })
      .catch(console.log);
  };

  useEffect(() => { if (id !== 'new') { GetAccount(); } }, [id]);

  return (
    <form>
      <div className="d-flex align-items-end">
        <h4 className="flex-grow-1">Hesap Bilgileri</h4>
      </div>
      {(account || id === 'new') && (
      <>
        <section className="grid">
          <section className="grid grid-4" id="account-info">
            <CommonInput
              label="Kullanıcı Adı"
              placeholder="eg: Alp Giray"
              register={register('account.firstName', { required: true })}
              defaultValue={account?.firstName}
            />
            <CommonInput
              label="Kullanıcı Soyadı"
              placeholder="eg: Savrum"
              register={register('account.lastName', { required: true })}
              defaultValue={account?.lastName}
            />
            <CommonInput
              label="Kullanıcı E-Posta"
              placeholder="eg: alp@savrum.com"
              register={register('account.email', { required: true })}
              defaultValue={account?.email}
            />
            <CommonInput
              label="Kullanıcı Telefon"
              placeholder="eg: +90 555 5555"
              register={register('account.phone', { required: true })}
              defaultValue={account?.phone}
            />
          </section>
          <section className="grid grid-3">
            {id !== 'new' && (
            <>
              <CommonInput
                label="Türü"
                placeholder="eg: Admin"
                // register={register('account.type')}
                defaultValue={strings[`entity-${account?.type}`]}
                disabled
              />
              <CommonInput
                label="Referans Kodu"
                placeholder="HITR-000-000"
                // register={register('account.reference_code')}
                defaultValue={account?.reference_code}
                disabled
              />
              <CommonInput
                label="Oluşuturulma Tarihi"
                register={register('account.createdAt')}
                defaultValue={dayjs.utc(account?.createdAt).format('DD MMMM YYYY, HH:mm')}
                disabled
              />
            </>
            )}
          </section>
        </section>
        {(account?.reference_codes.length > 0 && !noReferences) && (<ReferencesTable data={account?.reference_codes} borderless />)}
      </>
      )}
    </form>
  );
};

export default Account;
