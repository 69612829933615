/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */

const ReaderAuthCode = ({
  code, title, subtitle, onClose,
}) => (
  <section
    className="position-fixed center w-100 h-100 align-items-center justify-content-center"
    style={{
      display: code ? 'flex' : 'none',
      background: '#000000aa',
      zIndex: 2,
    }}
  >
    <div className="modal d-flex align-items-center justify-content-center">
      <div className="modal-dialog w-50">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center">
            <img
              alt="QR Kod"
              src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${code}`}
              className="w-50 mt-3"
            />
            {/* <p className="my-2">{code}</p> */}
            <p className="text-center my-4" style={{ fontSize: '0.75rem' }}>
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ReaderAuthCode;
