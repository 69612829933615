/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { Link } from 'wouter';
import { EventSalesTable } from '../../../../components/tables';

const EventSales = ({ show, event, params }) => (
  <section className="grid" style={{ display: show ? 'grid' : 'none' }}>
    <main className="main appbar">
      <div className="m-0 h4 d-flex flex-row flex-grow-1">
        {(params.id !== 'new')
          ? (
            event?.sales.length > 0
              ? `Toplam ${event?.sales?.length} Satış ve ${event?.sales?.reduce((a, b) => a + b.sessions, 0)} Seans Var`
              : 'Hiç Etkinlik Satış Bilgisi Girilmemiş'
          )
          : 'Satış eklemeden önce lütfen etkinliği oluşturun.'}
      </div>
      <Link to={`/events/${event?._id}/sales/new`}>
        <button
          id="event-sales-new"
          type="button"
          className="btn btn-primary"
          disabled={params.id === 'new'}
          onClick={() => null}
        >
          <i className="fa fa-plus mr-2" />
          Yeni Satış Başlat
        </button>
      </Link>
    </main>
    {(params.id !== 'new' && event?.sales?.length > 0) && <EventSalesTable event={event} />}
  </section>
);

export default EventSales;
