/* eslint-disable max-len */
import { Fragment } from 'react';
import { observer } from 'remx';
import { Link } from 'wouter';

import stores from '../../libs/stores';

const Appbar = () => (
  <nav className="appbar">
    {stores.getAppbar()?.back && (
    <Link to={stores.getAppbar()?.back}>
      <i
        className="text-primary p-0 fa fa-arrow-left mr-4 pointer"
        style={{ fontSize: '1.5rem', fontWeight: 'normal' }}
      />
    </Link>
    )}
    <div className="flex-grow-1">
      <div className="d-flex flex-row">
        <div className="flex-grow-1 title">{stores.getAppbar()?.left}</div>
        {stores.getAppbar()?.right}
      </div>
      <div className="d-flex">
        {(stores.getBreadCrumbs() || []).map(({ title, to }, i) => (
          title ? (
            <Fragment key={title}>
              <Link to={to}>{title}</Link>
              {(i < stores.getBreadCrumbs().length - 1) && <p className="text-primary mx-1">/</p>}
            </Fragment>
          ) : null
        ))}
      </div>
    </div>
    <button
      type="button"
      className="btn btn-secondary mobile-only ml-2"
      onClick={() => stores.setShowSidebar(!stores.getShowSidebar())}
    >
      <i className="fa fa-bars" />
    </button>
  </nav>
);

export default observer(Appbar);
