import { Tour } from 'react-rtg';

const Help = ({
  children, open, startAt, setOpen,
}) => (
  <Tour isOpen={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} startAt={startAt}>
    {children}
  </Tour>
);

export default Help;
