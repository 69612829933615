import { CommonInput } from '../../../../components/widgets';

const PaywallForm = ({ register, provider }) => (
  <section className="grid">
    <CommonInput
      label="Paywall Public Client"
      register={register('credentials.paywallClient', { required: true })}
      defaultValue={provider?.credentials?.paywallClient}
    />
    <CommonInput
      label="Paywall Public Key"
      register={register('credentials.paywallKey', { required: true })}
      defaultValue={provider?.credentials?.paywallKey}
    />
    <CommonInput
      label="Paywall Private Client"
      register={register('credentials.paywallPrivateClient', { required: true })}
      defaultValue={provider?.credentials?.paywallPrivateClient}
    />
    <CommonInput
      label="Paywall Private Key"
      register={register('credentials.paywallPrivateKey', { required: true })}
      defaultValue={provider?.credentials?.paywallPrivateKey}
    />
  </section>
);

export default PaywallForm;
