/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
/* eslint-disable max-len */

import { encode } from 'querystring';

const AddressParser = (a) => `${a?.neighborhood || ''} ${a?.street || ''} ${a?.no ? `No: ${a.no}` : ''}\n${a?.district || ''} ${a?.city || ''}`;
const DashboardGrapher = (daysinmonth, label, data) => encodeURI(JSON.stringify({
  type: 'line',
  data: {
    labels: Object.keys(Array.from(Array(daysinmonth))).map((i) => (Number(i) + 1)),
    datasets: [
      {
        label,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 1,
        data: Object.keys(Array.from(Array(data.length)))
          .map(() => Math.floor(10 + ((Math.random() * 90)))),
      },
    ],
  },
  options: {
    responsive: true,
    scales: {
      xAxes: [{ ticks: { autoSkip: false, fontSize: 10 } }],
      yAxes: [{ ticks: { fontSize: 10 } }],
    },
    legend: { position: 'top' },
    title: { display: false, text: 'Chart.js Bar Chart' },
  },
}));

const QuerifySearch = (form) => encode(Object.entries(form).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {}));

const DecodifySearch = (search) => (search).replace('?', '').split('&')
  .map((param) => param.split('='))
  .reduce((values, [key, value]) => {
    values[key] = value;
    return values;
  }, {});

const Thousandify = (number) => number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export {
  Thousandify,
  AddressParser,
  DashboardGrapher,
  QuerifySearch,
  DecodifySearch,
};
