import { useEffect, useState } from 'react';
import { observer } from 'remx';
import { Step } from 'react-rtg';

import { PaginationInput } from '../../../../components/widgets';
import { DecodifySearch } from '../../../../utils/StringUtils';

// import strings from '../../../../localization';

import BasicInfo from './BasicInfo';
import Sessions from './Sessions';
import Help from '../../../../components/utils/Help';
import stores from '../../../../libs/stores';

const PAGES = ['Temel Bilgiler', 'Etkinlik Seansları'];

const BasicNormalHelp = ({ help, setHelp }) => (
  <Help open={help} setOpen={setHelp}>
    <Step selector="#basic-info" placement="bottom">
      <p>Burada satış ile ilgili temel bilgiler görünür</p>
    </Step>
    <Step selector="#sale-venue" placement="top">
      <p>Buradan etkinliğin yapılacağı mekanı seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-confirm-ES Events" placement="left">
      <p>Burada ES Events&apos;in etkinliğinizi onaylayıp onaylamadığı görünür</p>
    </Step>
    <Step selector="#sale-confirm-venue" placement="left">
      <p>Burada Mekanın etkinliğinizi onaylayıp onaylamadığı görünür</p>
    </Step>
    <Step selector="#sale-provider" placement="top">
      <p>Buradan etkinlik için kullanmak istediğiniz ödeme sağlayıcısını seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-currency" placement="top">
      <p>Buradan ödeme almak istediğiniz para birimini seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-contract" placement="top">
      <p>Buradan da etkinlik satışınız için sözleşmenizi seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-rules" placement="top">
      <p>Buraya da etkinlik kurallarınızı yazabilirsiniz</p>
    </Step>
    <Step selector="#pagination-2" placement="top">
      <p>Etkinlik seanslarını görmek için buraya tıklayabilirsiniz</p>
    </Step>
  </Help>
);

const BasicEmptyHelp = ({ help, setHelp }) => (
  <Help open={help} setOpen={setHelp}>
    <Step selector="#basic-info" placement="bottom">
      <p>Burada satış ile ilgili temel bilgiler görünür</p>
    </Step>
    <Step selector="#sale-venue" placement="top">
      <p>Buradan etkinliğin yapılacağı mekanı seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-confirm-ES Events" placement="left">
      <p>Burada ES Events&apos;in etkinliğinizi onaylayıp onaylamadığı görünür</p>
    </Step>
    <Step selector="#sale-confirm-venue" placement="left">
      <p>Burada Mekanın etkinliğinizi onaylayıp onaylamadığı görünür</p>
    </Step>
    <Step selector="#sale-provider" placement="top">
      <p>Buradan etkinlik için kullanmak istediğiniz ödeme sağlayıcısını seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-currency" placement="top">
      <p>Buradan ödeme almak istediğiniz para birimini seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-contract" placement="top">
      <p>Buradan da etkinlik satışınız için sözleşmenizi seçebilirsiniz</p>
    </Step>
    <Step selector="#sale-rules" placement="top">
      <p>Buraya da etkinlik kurallarınızı yazabilirsiniz</p>
    </Step>
    <Step selector="#create-sale" placement="bottom">
      <p>Herşey tamamsa etkinlik satışını oluşturmak için buraya tıklayın</p>
    </Step>
  </Help>
);

const EventSale = ({ params }) => {
  const [step, setStep] = useState(Number(DecodifySearch(window.location.search)?.step) || 0);

  const [venue, setVenue] = useState(null);
  const [sale, setSale] = useState(null);

  const [paid, setPaid] = useState(null);

  const [help, setHelp] = useState(false);

  useEffect(() => {
    setHelp(false);
    if (step === 0) {
      stores.setTutorial(() => { setHelp(true); });
    }
  }, [step]);

  return (
    <main className="grid">
      {/* HELP START  */}
      {(params.id === 'new')
        ? <BasicEmptyHelp {...{ help, setHelp }} />
        : <BasicNormalHelp {...{ help, setHelp }} />}
      {/* HELP END  */}

      <PaginationInput
        className="mr-2"
        content={PAGES}
        page={step}
        onChange={setStep}
      />
      <BasicInfo
        show={step === 0}
        page={PAGES[step]}
        step={step}
        params={params}
        onPaidChange={setPaid}
        onVenueChange={setVenue}
        onSaleChange={setSale}
      />
      <Sessions
        show={step === 1}
        params={params}
        venue={venue}
        paid={paid}
        sale={sale}
      />
    </main>
  );
};

export default observer(EventSale);
