const CenteredInformation = ({
  title, subtitle, fixed, style,
}) => (
  <span
    className={`center ${fixed ? 'left-pad-sidebar' : ''}`}
    style={{ position: fixed ? 'fixed' : 'absolute', ...style }}
  >
    <h1 className="mb-0 text-center">
      {title}
    </h1>
    <p className="text-center">{subtitle}</p>
  </span>
);

export default CenteredInformation;
