/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';
import { get } from '../../libs/client';
import { CommonSelect } from '../widgets';
import LoadingIndicator from './LoadingIndicator';

const SEATING_TARGET = 'https://seating.hibilet.com';
// const SEATING_TARGET = 'http://localhost:5173';
// const SEATING_TARGET = 'http://192.168.2.243:1234';
// const SEATING_TARGET = 'http://10.241.158.51:1234';

const SeatingEngine = ({
  plan, value, tickets, session, params, readonly, className, onClose, onSelect,
}) => {
  const engine = useRef(null);
  const [loading, setLoading] = useState('Oturma planı yükleniyor...');
  const [distribution, setDistribution] = useState(value);
  const [layout, setLayout] = useState(null);

  const [selected, setSelected] = useState(-1);
  const _selected = useRef(selected);
  const [func, setFunc] = useState('roam');
  const _func = useRef(func);

  const Message = (msg) => {
    if (msg.method) {
      engine?.current?.contentWindow?.postMessage(msg, '*');
    }
  };

  const SelectSeats = (seats) => {
    const _distribution = distribution;
    if (_func.current === 'roam') {
      seats.forEach(({ id }) => {
        if (_distribution[id]?.includes(_selected.current)) {
          _distribution[id] = _distribution[id]?.filter((i) => i !== _selected.current);
        } else if (Array.isArray(_distribution[id])) {
          _distribution[id].push(_selected.current);
        } else {
          _distribution[id] = [_selected.current];
        }
      });
    }
    if (_func.current === 'multi-add') {
      seats.forEach(({ id }) => {
        if (!_distribution[id]?.includes(_selected.current)) {
          if (Array.isArray(_distribution[id])) {
            _distribution[id].push(_selected.current);
          } else {
            _distribution[id] = [_selected.current];
          }
        }
      });
    }
    if (_func.current === 'multi-remove') {
      seats.forEach(({ id }) => {
        if (_distribution[id]?.includes(_selected.current)) {
          _distribution[id] = _distribution[id]?.filter((i) => i !== _selected.current);
        }
      });
    }
    setDistribution(_distribution);
    onSelect(_distribution);
    Message({ method: 'POST/ LoadDistribution', data: _distribution });
    Message({ method: 'POST/ PaintSeats' });
  };

  const GetLayout = () => {
    setLoading('Oturma planı indiriliyor...');
    get(`/venues/seating/${plan?._id || plan}`)
      .then(({ data }) => { setLayout(data); })
      .catch(() => alert('Oturma planı indirilemedi'));
  };

  const GetOccupation = () => {
    setLoading('Koltuk doluluğu indiriliyor...');
    get(`/events/${params.eid}/sales/${params.id}/sessions/${session._id}/occupations`)
      .then(({ data }) => {
        // Remove already purchased seats
        const _distribution = distribution;
        data.forEach((o, i) => {
          if (o === -1) {
            _distribution[i] = null;
          }
        });
        setDistribution(_distribution);
        Message({ method: 'POST/ LoadDistribution', data: _distribution }, '*');
        Message({ method: 'POST/ LoadOccupation', data }, '*');
        Message({ method: 'POST/ PaintSeats' }, '*');
        setLoading(false);
      })
      .catch(() => {
        Message({ method: 'POST/ LoadDistribution', data: distribution }, '*');
        Message({ method: 'POST/ PaintSeats' }, '*');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (layout) {
      Message({ method: 'POST/ UserType', data: 'organizer' }, '*');
      Message({ method: 'POST/ Category', data: selected }, '*');
      Message({ method: 'POST/ Layout', data: layout }, '*');
      Message({ method: 'POST/ PaintSeats' }, '*');
    }
  }, [layout]);

  useEffect(() => {
    if (!loading && distribution?.length > 0) {
      onSelect(distribution);
      Message({ method: 'POST/ LoadDistribution', data: distribution });
    }
  }, [distribution]);

  useEffect(() => { if (engine.current) { Message({ method: `POST/ ${func}` }); } }, [func]);

  useEffect(() => {
    Message({ method: 'POST/ Category', data: selected }, '*');
    if (selected > -1) {
      Message({ method: 'POST/ PaintSeats' }, '*');
    }
  }, [selected]);

  useEffect(() => { _func.current = func; }, [func]);

  const Controllers = {
    'RES/ SelectedSeats': SelectSeats,
    'HOOK/ OnPaint': () => null,
    'HOOK/ OnLoadLayout': () => (!session._id.includes('new') ? GetOccupation() : (() => {
      Message({ method: 'POST/ LoadDistribution', data: distribution }, '*');
      Message({ method: 'POST/ PaintSeats' }, '*');
      setLoading(false);
    })()),
    'HOOK/ OnMode': (mode) => setFunc(mode),
  };

  const MessageBroker = ({ origin, data }) => {
    if (origin !== window.origin) {
      try {
        Controllers[data.type](data.data);
      } catch (e) {
        console.log(data);
      }
    }
  };

  const RemoveEventListeners = () => {
    window.removeEventListener('message', MessageBroker);
  };

  useEffect(() => {
    if (engine.current) { window.addEventListener('message', MessageBroker); }
    return () => {
      engine.current = null;
      RemoveEventListeners();
    };
  }, [engine]);

  const ENGINE_FUNCTIONS = [
    { name: 'spacer' },
    { name: 'Roam', title: 'Gezinti Modu', icon: 'arrows' },
    { name: 'MultiSelectAdd', title: 'Çoklu Seçim (Ekleme)', icon: 'plus-square-o' },
    { name: 'MultiSelectRemove', title: 'Çoklu Seçim (Silme)', icon: 'minus-square-o' },
    // { name: 'spacer' },
    // { name: 'ZoomIn', title: 'Yakınlaştır', icon: 'search-plus', once: true },
    // { name: 'ZoomOut', title: 'Uzaklaştır', icon: 'search-minus', once: true },
    // { name: 'CenterCamera', title: 'Ortala', icon: 'crosshairs', once: true },
  ];

  return (
    <div className={`${readonly ? '' : 'seating-engine center'} ${className || ''}`}>
      <section className="d-flex flex-column">
        {!readonly && (
          <main className="main appbar seating-engine-appbar">
            <h4 className="flex-grow-1">
              {layout?.name || 'Oturma Planı'}
            </h4>
            <CommonSelect
              className="mr-2"
              placeholder="Lütfen bilet türü seçin"
              placeholderValue={-1}
              options={tickets.map((t, i) => ({
                value: i,
                name: `${t.name} (${distribution.reduce((c, s) => c + (s && s?.includes(i)), 0) || 'Koltuk Seçilmemiş'})`,
                // name: `${t.name}`,
              }))}
              onChange={({ target }) => {
                setSelected(Number(target.value));
                _selected.current = Number(target.value);
              }}
            />
            {ENGINE_FUNCTIONS.map((f) => (
              f.name === 'spacer' ? <div className="mx-2 mobile-only" /> : (
                <button
                  title={f.title}
                  type="button"
                  className={`mr-2 btn ${func === f.name ? 'btn-primary' : 'btn-secondary'} text-left mobile-only`}
                  onClick={() => (func === f.name
                    ? setFunc(ENGINE_FUNCTIONS[0].name)
                    : (f.once)
                      ? Message({ method: `POST/ ${func}` })
                      : setFunc(f.name)
                  )}
                >
                  <i className={`fa fa-${f.icon}`} />
                </button>
              )
            ))}
            <button
              type="button"
              className="mr-2 ml-3 btn btn-primary"
              onClick={() => { onClose(); }}
            >
              <i className="fa fa-save mr-2" />
              KAYDET
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => { onClose(); }}
            >
              <i className="fa fa-close" />
            </button>
          </main>
        )}
        <main className="flex-grow-1">
          <div className=" w-100 h-100">
            <iframe
              ref={engine}
              className="seating-plan w-100 h-100"
              style={{ background: 'white' }}
              title="Oturma Planı"
              src={SEATING_TARGET}
              loading="lazy"
              onError={(e) => console.log(e)}
              onLoad={() => GetLayout()}
            />
            {loading && <LoadingIndicator message={loading} />}
          </div>
        </main>
      </section>
    </div>
  );
};

export default SeatingEngine;
