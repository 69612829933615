/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import CommonInput from './CommonInput';
import strings from '../../localization';

const ArtistInput = ({ data, disabled, onChange }) => {
  const { register, handleSubmit, reset } = useForm();
  const [artists, setArtists] = useState(data || []);

  const AddArtist = (form) => {
    setArtists([...artists, form]);
    reset();
  };

  useEffect(() => {
    onChange({ target: { value: artists } });
  }, [artists]);

  return (
    <div className="grid">
      <div className="grid grid-6 grid-2-m">
        {artists.map((i) => (
          <div key={i.name}>
            <div className="card">
              {!disabled && (
                <button
                  type="button"
                  label="delete"
                  className="position-absolute btn btn-danger fa fa-times"
                  style={{ top: '0.5rem', right: '0.5rem' }}
                  onClick={() => setArtists(artists.filter((j) => j.name !== i.name))}
                />
              )}
              <img
                onError={(e) => e.target.src = 'https://fakeimg.pl/512x512/?text=Hata'}
                src={i.photo}
                className="card-img-top"
                style={{ objectFit: 'cover', height: '10rem' }}
                alt="..."
              />
              <p className="p-2 mb-0">{i.name}</p>
            </div>
          </div>
        ))}
      </div>
      <section className="grid grid-2 grid-1-m">
        <CommonInput
          disabled={disabled}
          label={strings['placeholder-artist-name']}
          placeholder={strings['placeholder-artist-name']}
          register={register('name', { required: true })}
        />
        <span className="d-flex align-items-end">
          <CommonInput
            disabled={disabled}
            className="flex-grow-1 mr-3"
            label={strings['placeholder-artist-photo']}
            placeholder={strings['placeholder-artist-photo']}
            register={register('photo', {
              required: true,
              validate: (value) => {
                if (value.includes('base64')) {
                  alert('Resim linki base64 olamaz! Lütfen başka bir link deneyin.');
                  return false;
                } if (value.includes('http://')) {
                  alert('Resim linki http olamaz! Lütfen https ile başlayan bir link deneyin.');
                  return false;
                } return true;
              },
            })}
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit(AddArtist)()}
          />
          <button
            disabled={disabled}
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(AddArtist)}
          >
            <i className="fa fa-plus mr-2" />
            Sanatçı Ekle
          </button>
        </span>
      </section>
    </div>
  );
};

export default ArtistInput;
