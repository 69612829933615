/* eslint-disable max-len */
import remx from 'remx';

const state = remx.state({
  tab: '/',
  account: null,
  appbar: null,
  notifications: [],
  showSidebar: false,
  breadCrumbs: [],
  tutorial: () => null,
});

export default {
  ...remx.getters({
    getTab() { return state.tab; },
    getShowSidebar() { return state.showSidebar; },
    getAccount() { return state.account; },
    getAppbar() { return state.appbar; },
    getNotifications() { return state.notifications; },
    getBreadCrumbs() { return state.breadCrumbs; },
    getTutorial() { return state.tutorial; },
  }),

  ...remx.setters({
    setTab(tab) { state.tab = tab; },
    setShowSidebar(showSidebar) { state.showSidebar = showSidebar; },
    setAccount(account) { state.account = account; },
    setAppbar(appbar) { state.appbar = appbar; },
    setNotifications(notifications) { state.notifications = notifications; },
    setBreadCrumbs(breadCrumbs) { state.breadCrumbs = breadCrumbs; },
    setTutorial(tutorial) { state.tutorial = tutorial; },
    pushBreadCrumbs(breadCrumbs) {
      breadCrumbs.forEach((element) => {
        state.breadCrumbs.push(element);
      });
    },
  }),
};
