const LoadingIndicator = ({
  color = 'primary', width = 3, height = 3, show = true, message,
}) => (
  <div className="position-fixed center d-flex flex-column align-items-center" style={{ display: show ? 'block' : 'none', zIndex: 1 }}>
    <span
      className={`spinner-border text-${color}`}
      style={{ width: `${width}rem`, height: `${height}rem` }}
      role="status"
    />
    {message && <p className="text-center mt-2">{message}</p>}
  </div>
);

export default LoadingIndicator;
