import Localization from 'react-localization';
import { getLang } from '../libs/storage';

import tr from './tr.json';
// import en from './en.json';

const localization = new Localization({
  tr,
//   en,
});
localization.setLanguage(getLang() || 'tr');

export default localization;
