/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Step } from 'react-rtg';

import { useLocation } from 'wouter';
import { PaginationInput } from '../../../../components/widgets';
import { LoadingIndicator } from '../../../../components/utils';
import {
  get, post, put,
} from '../../../../libs/client';

// import strings from '../../../../localization';

import BasicInfo from './BasicInfo';
import Sales from './Sales';

import stores from '../../../../libs/stores';
import ReaderAuthCode from './ReaderAuthCode';
import { DecodifySearch } from '../../../../utils/StringUtils';
import Help from '../../../../components/utils/Help';

const PAGES = ['Temel Bilgiler', 'Etkinlik Satışları'];

const NormalHelp = ({
  help, setHelp, step, sales,
}) => (step === 0 ? (
  <Help open={help} setOpen={setHelp}>
    <Step selector="#pagination-1" placement="bottom">
      <p>Bu sekmede etkinliğiniz hakkında adı, açıklamaları, fotoğrafları gibi temel bilgileri görüp düzenleyebilirsiniz</p>
    </Step>
    <Step selector="#pagination-2" placement="bottom">
      <p>Bu sekmede ise etkinliğiniz için yapacağınız satışları görebilir, ve düzenleyebilirsiniz</p>
    </Step>
    <Step selector="#event-hide" placement="bottom">
      <p>Bu buton ile etkinlinliğinizi ve tüm satışlarını ES Events uygulmasından gizleyebilisiniz</p>
    </Step>
    <Step selector="#event-gate" placement="bottom">
      <p>Bu buton ile kapı operasyonu için bilet okuma kodu oluşturabilirsiniz</p>
    </Step>
    <Step selector="#event-detail-basic" placement="bottom">
      <p>Burada etkinliğinizin adını, ve hangi kategoride olacağını düzenleyebilirsiniz</p>
    </Step>
    <Step selector="#event-detail-desc" placement="bottom">
      <p>Burası etkinliğiniz için açıklama girebileceğiniz yerdir. 300 karakterden fazla yazmamanız tavsiye edilir.</p>
    </Step>
    <Step selector="#event-detail-image" placement="top">
      <p>Burası etkinliğinizin ES Events uygulamasında kullanılacak olan görselidir. 1:1 ölçekte kullanmanız tavsiye edilir</p>
    </Step>
    <Step selector="#event-detail-qr" placement="left">
      <p>Burası etkinliğinizin biletinde kullanılacak özel görselidir. 1:1 ölçekte kullanmanız tavsiye edilir</p>
    </Step>
    <Step selector="#event-detail-artists" placement="top">
      <p>Buradan etkinliğinize sanatçı ekleyebilir veya çıkarabilirsiniz</p>
    </Step>
    <Step selector="#event-detail-alerts" placement="top">
      <p>Bu kısım sadece etkinlik konusunda acil duyuru yapılmaı gereken durumlarda (iptal, iade, erteleme vs.) kullanıcıları bilgilendirmek için kullanılmalıdır.</p>
    </Step>
    <Step selector="#event-alter" placement="bottom">
      <p>Etkinliğinizi kaydetmek için bu butona tıklamayı unutmayın</p>
    </Step>
  </Help>
) : (
  sales.length > 0 ? (
    <Help open={help} setOpen={setHelp}>
      <Step selector="#event-sales-table" placement="center">
        <p>Etkinliğinizin farklı mekanlardaki satışları burada listelenir</p>
      </Step>
      <Step selector="#event-sales-new" placement="bottom">
        <p>Bu buton ile yeni bir mekanda, yeni bir satış başlatabilirsiniz</p>
      </Step>
      <Step selector="#event-sales-table-vendor" placement="top">
        <p>Satışınız ES Events tarafından oyanlanmış mı buradan görebilirsiniz</p>
      </Step>
      <Step selector="#event-sales-table-venue" placement="top">
        <p>Satışınız mekan tarafından oyanlanmış mı buradan görebilirsiniz</p>
      </Step>
      <Step selector="#event-sales-table-details" placement="top">
        <p>Etkinlik satışınızın detaylarına buradan ulaşabilirsiniz</p>
      </Step>
    </Help>
  ) : (
    <Help open={help} setOpen={setHelp}>
      <Step selector="#event-sales-table" placement="center">
        <p>Etkinliğinizin farklı mekanlardaki satışları burada listelenir</p>
      </Step>
      <Step selector="#event-sales-new" placement="left">
        <p>Görünüşe göre etkinliğinizin satışı yok yok, yeni satış açmak için bu butona basın</p>
      </Step>
    </Help>
  )
)
);

const EmptyHelp = ({ help, setHelp }) => (
  <Help open={help} setOpen={setHelp}>
    <Step selector="#event-detail-basic" placement="bottom">
      <p>Burada etkinliğinizin adını, ve hangi kategoride olacağını düzenleyebilirsiniz</p>
    </Step>
    <Step selector="#event-detail-desc" placement="bottom">
      <p>Burası etkinliğiniz için açıklama girebileceğiniz yerdir. 300 karakterden fazla yazmamanız tavsiye edilir.</p>
    </Step>
    <Step selector="#event-detail-image" placement="top">
      <p>Burası etkinliğinizin ES Events uygulamasında kullanılacak olan görselidir. 1:1 ölçekte kullanmanız tavsiye edilir</p>
    </Step>
    <Step selector="#event-detail-qr" placement="left">
      <p>Burası etkinliğinizin biletinde kullanılacak özel görselidir. 1:1 ölçekte kullanmanız tavsiye edilir</p>
    </Step>
    <Step selector="#event-detail-artists" placement="top">
      <p>Buradan etkinliğinize sanatçı ekleyebilir veya çıkarabilirsiniz</p>
    </Step>
    <Step selector="#event-alter" placement="bottom">
      <p>Etkinliğinizi oluşturmak için bu butona tıklamayı unutmayın</p>
    </Step>
  </Help>
);

const Event = ({ params }) => {
  const [location, setLocation] = useLocation();
  const [step, setStep] = useState(0);

  const [event, setEvent] = useState(null);
  const [categories, setCategories] = useState([]);
  const [readerAuthCode, setReaderAuthCode] = useState();

  const [help, setHelp] = useState(false);

  useEffect(() => {
    setHelp(false);
  }, [step]);

  stores.setTutorial(() => {
    if (params.id === 'new') { setLocation(`${location}?tutorial=true`); }
    setHelp(true);
  });

  const {
    control, watch, register, handleSubmit, reset, setValue,
  } = useForm({ defaultValues: event });

  const { fields, append, remove } = useFieldArray({ control, name: 'tickets' });

  const [name] = [watch('name')];

  const GetEvent = () => get(`/events/${params.id}`)
    .then(({ data }) => setEvent(data))
    .catch((err) => console.log(err));
  const CreateEvent = (form) => post('/events/', form)
    .then(({ data }) => { alert('Etkinlik oluşturuldu.'); setLocation(`/events/${data._id}?step=1`); })
    .catch(() => alert('Etkinlik oluştururken bir hata oluştu'));
  const UpdateEvent = (form) => put(`/events/me/${params.id}`, form)
    .then(() => { alert('Etkinlik güncellendi'); GetEvent(); })
    .catch(() => alert('Etkinliği güncellerken bir hata oluştu'));

  const UpdateReaderAuthCode = () => put(`/events/${params.id}/readerAuthCode`)
    .then(({ data }) => setReaderAuthCode((data)))
    .catch(() => alert('Kapı operasyon kodu oluşturulurken bir hata oldu.'));

  const GetEventCategories = () => get('/events/categories')
    .then(({ data }) => setCategories(data))
    .catch((err) => console.log(err));

  useEffect(() => {
    GetEventCategories();
    setStep(Number(DecodifySearch(window.location.search)?.step) || 0);
    if (params.id !== 'new') { GetEvent(); }
  }, [params]);

  useEffect(() => {
    stores.setAppbar({
      back: '?',
      left: (<h2 className="m-0">{event ? `${event?.name} detayları` : (name ? `${name} detayları` : 'Yeni Etkinlik')}</h2>),
      right: (
        <div>
          {params.id !== 'new' && (
          <>
            <button
              type="button"
              id="event-hide"
              className={`btn btn-${event?.active ? 'danger' : 'success'} mr-2`}
              onClick={() => UpdateEvent({ active: !event.active })}
            >
              <i className={`fa fa-${event?.active ? 'ban' : 'check'} mr-2`} />
              {event?.active ? 'ETKİNLİĞİ GİZLE' : 'ETKİNLİĞİ LİSTELE'}
            </button>
            {event?.sales?.length > 0 && (
              <button
                type="button"
                id="event-gate"
                className="btn btn-primary mr-2"
                onClick={() => UpdateReaderAuthCode()}
              >
                <i className="fa fa-qrcode mr-2" />
                KAPI OPERASYON
              </button>
            )}
          </>
          )}
          <button
            id="event-alter"
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(params.id === 'new' ? CreateEvent : UpdateEvent)}
            disabled={step === 2}
          >
            <i className="fa fa-save mr-2" />
            {params.id === 'new' ? 'ETKİNLİK OLUŞTUR' : 'KAYDET'}
          </button>
        </div>
      ),
    });
    stores.setBreadCrumbs([
      { to: '/events', title: 'Etkinlikler' },
      { to: `/events/${event?._id || 'new'}`, title: event?.name || 'Yeni' },
      { to: `/events/${event?._id}`, title: PAGES[step] },
    ]);
  }, [event, step, name]);

  return (
    (event || params.id === 'new')
      ? (
        <main className="grid">
          {/* HELP START  */}
          {params.id === 'new'
            ? <EmptyHelp {...{ help, setHelp }} />
            : (
              <NormalHelp
                {...{
                  help, setHelp, step, setStep,
                }}
                sales={event?.sales}
              />
            )}
          {/* HELP END  */}

          {params.id !== 'new' && (
            <PaginationInput
              className="mr-2"
              content={PAGES}
              page={step}
              onChange={setStep}
            />
          )}
          <BasicInfo
            show={step === 0}
            register={register}
            event={event}
            control={control}
            categories={categories}
            params={params}
            watch={watch}
          />
          <Sales
            show={step === 1}
            register={register}
            event={event}
            params={params}
            reset={reset}
            setValue={setValue}
            fields={fields}
            append={append}
            remove={remove}
          />
          <ReaderAuthCode
            title="Kapı operasyonları için QR Kod"
            subtitle={'ES Events uygulamasına kullanıcı olarak giriş yapın.\nEtkinliği arayın ve etkinlik fotoğrafına 10 saniye basılı tutun.\nEkrandaki QR kodu uygulamaya okutun.'}
            code={readerAuthCode}
            onClose={() => setReaderAuthCode(null)}
          />
        </main>
      )
      : <LoadingIndicator />
  );
};

export default Event;
