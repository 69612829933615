/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Step } from 'react-rtg';
import { observer } from 'remx';

import { LoadingIndicator } from '../../../components/utils';
import Account from '../../../components/forms/Account';

import { get, put } from '../../../libs/client';
import stores from '../../../libs/stores';

import Corporate from './Corporate';
import Profile from './Profile';
import Help from '../../../components/utils/Help';

const Organizer = ({ params }) => {
  const [data, setData] = useState(null);
  const { register, watch, handleSubmit } = useForm();

  const [help, setHelp] = useState(null);
  stores.setTutorial(() => { setHelp(true); });

  const GetOrganizer = () => get('/organizers/me')
    .then((res) => (res.data ? setData(res.data) : setData({})))
    .catch((err) => console.log(err));

  const UpdateOrganizer = (form, soft) => {
    const _form = { ...form, corporate: { ...form.corporate, address: form.address } };
    delete _form.address;
    put('/accounts/me', _form.account)
      .then(() => put('/organizers/me', _form)
        .then(() => {
          alert('Organizatör güncellendi');
          if (soft) { return GetOrganizer(); }
          return window.location.reload();
        })
        .catch(() => alert('Organizatör güncellenirken bir hata oldu')))
      .catch(() => alert('Hesap güncellenirken bir hata oldu'));
  };

  useEffect(() => { if (params.id !== 'new') { GetOrganizer(); } }, []);
  useEffect(() => {
    stores.setAppbar({
      back: '/home',
      left: (<h2 className="m-0">Organizatör Bilgileri</h2>),
      right: (
        <button type="button" className="btn btn-primary" onClick={handleSubmit(UpdateOrganizer)}>
          <i className="fa fa-save mr-2" />
          {data ? 'KAYDET' : 'ORGANİZATÖR OLUŞTUR'}
        </button>
      ),
    });
    stores.setBreadCrumbs([
      { to: '/organizers', title: 'Profil' },
      { to: `/organizers/${data?._id}`, title: data?.name },
    ]);
  }, [data]);

  return (
    (data || params.id === 'new')
      ? (
        <main className="grid">
          {/* HELP START  */}
          <Help open={help} setOpen={setHelp}>
            <Step selector="#account-info" placement="top">
              <p>Organizatör hesabınızın kullanıcı bilgilerini buradan güncelleyebilirsiniz</p>
            </Step>
            <Step selector="#references-table" placement="top">
              <p>
                Burada ES Events&apos;in size paylaşmanız için verdiği refereans kodları bulunur
              </p>
            </Step>
            <Step selector="#basic-info" placement="top">
              <p>Bu kısımdan hesabınızla ilgili bilgileri düzenleyebilirsiniz</p>
            </Step>
            <Step selector="#basic-info-logo" placement="top">
              <p>
                Organizatör logosu için link verirken, lütfen
                kullanım hakkının size ait olduğundan emin olun
              </p>
            </Step>
            <Step selector="#basic-info-social" placement="top">
              <p>
                Bu kısımdan verdiğiniz bilgiler ile ES Events kullanıcılarını
                kendi sosyal medya hesaplarınıza yönlendirebilirsiniz
              </p>
            </Step>
            <Step selector="#corporate-info" placement="top">
              <p>Bu kısma, ticari ünvan bilgilerinizi girmeniz gerekmektedir</p>
            </Step>
            <Step selector="#address" placement="top">
              <p>Bu kısma da şirket adresinizi girmeniz gerekmektedir</p>
            </Step>
          </Help>
          {/* HELP END  */}
          <Account {...{ register }} id={data?.owner} />
          <Profile {...{ register, watch, data }} />
          <Corporate {...{ register, watch, data }} />
        </main>
      )
      : <LoadingIndicator />
  );
};

export default observer(Organizer);
