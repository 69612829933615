import { CommonInput } from '../../../../components/widgets';

const IyzicoForm = ({ register, provider }) => (
  <section className="grid grid-2">
    <CommonInput
      label="Iyzico API Key"
      register={register('credentials.iyzicoApiKey', { required: true })}
      defaultValue={provider?.credentials?.iyzicoApiKey}
    />
    <CommonInput
      label="Iyzico Secret Key"
      register={register('credentials.iyzicoSecret', { required: true })}
      defaultValue={provider?.credentials?.iyzicoSecret}
    />
  </section>
);

export default IyzicoForm;
