/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'wouter';
import { Step } from 'react-rtg';
import { observer } from 'remx';

import { CommonInput, CommonSelect } from '../../../../components/widgets';
import { LoadingIndicator } from '../../../../components/utils';

import FormIyzico from './FormIyzico';
import FormWeepay from './FormWeepay';
import FormCraftgate from './FormCraftgate';
import FormPaywall from './FormPaywall';

import { get, put, post } from '../../../../libs/client';
import stores from '../../../../libs/stores';

import PAYMENT_PROVIDERS from '../../../../constants/payment-providers.json';
import Help from '../../../../components/utils/Help';

const Provider = ({ params }) => {
  const { register, watch, handleSubmit } = useForm({ defaultValues: { provider: 'paywall' } });
  const [provider, setProvider] = useState(null);
  const [selectedProvider] = [watch('provider', 'paywall')];
  const [, setLocation] = useLocation();

  const [help, setHelp] = useState(null);
  stores.setTutorial(() => { setHelp(true); });

  const GetProvider = () => get(`/organizers/providers/${params.id}`)
    .then(({ data }) => setProvider(data))
    .catch((err) => console.log(err));

  const UpdateProvider = (form) => put(`/organizers/providers/${params.id}`, form)
    .then(() => { alert('Ödeme sağlayıcı güncellendi'); setLocation('/providers'); })
    .catch(() => alert('Ödeme sağlayıcı güncellenirken bir hata oluştu.'));

  const CreateProvider = (form) => post('/organizers/providers/me', form)
    .then(() => { alert('Ödeme sağlayıcı oluşturuldu'); setLocation('/providers'); })
    .catch(() => alert('Ödeme sağlayıcı oluşturulurken bir hata oldu.'));

  useEffect(() => params.id !== 'new' && GetProvider(), [GetProvider]);
  useEffect(() => stores.setAppbar({
    left: (<h2 className="m-0">{provider ? `${provider?.name} detayları` : 'Yeni Ödeme Sağlayıcı' }</h2>),
    right: (
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleSubmit(params.id === 'new' ? CreateProvider : UpdateProvider)}
      >
        <i className="fa fa-save mr-2" />
        {params.id === 'new' ? 'SAĞLAYICI OLUŞTUR' : 'KAYDET'}
      </button>
    ),
  }), [provider]);

  return (
    (provider || params.id === 'new') ? (
      <main className="grid">
        {/* HELP START  */}
        <Help open={help} setOpen={setHelp}>
          <Step selector="#provider-name" placement="bottom">
            <p>Buradan, ödeme sağlayıcınıza akılda kalıcı bir isim verin</p>
          </Step>
          <Step selector="#provider-bank" placement="bottom">
            <p>Kullandığınız banka ismini girin</p>
          </Step>
          <Step selector="#provider-type" placement="bottom">
            <p>Ödeme sağlayıcı hizmeti aldığınız firmayı seçin</p>
          </Step>
          <Step selector="#provider-form" placement="top">
            <p>Ve ödeme sağlayıcınızdan aldığınız bilgileri kontrol ederek buraya girin</p>
          </Step>
          <Step placement="center">
            <p>Her şey doğru ise, satış yapmaya hazırsınız!</p>
          </Step>
        </Help>
        {/* HELP END  */}
        <form>
          <h4> Sağlayıcı Bilgileri</h4>
          <section className="grid grid-3">
            <CommonInput
              id="provider-name"
              label="Sağlayıcı Adı"
              register={register('name', { required: true })}
              placeholder="eg: Genel Satış"
              defaultValue={provider?.name}
            />
            <CommonInput
              id="provider-bank"
              label="Bağlı Banka"
              register={register('bank', { required: true })}
              placeholder="eg: Akbank"
              defaultValue={provider?.bank}
            />
            <CommonSelect
              disabled
              id="provider-type"
              label="Ödeme Sağlayıcı Servis"
              placeholder="Lütfen bir sağlayıcı seçin"
              options={PAYMENT_PROVIDERS}
              register={register('provider')}
              defaultValue={provider?.provider || 'paywall'}
            />
          </section>
        </form>
        <form id="provider-form">
          <h4>Sağlayıcı Detayları</h4>
          {(() => {
            switch (selectedProvider || provider?.provider) {
              case 'iyzico': return <FormIyzico {...{ register, provider }} />;
              case 'weepay': return <FormWeepay {...{ register, provider }} />;
              case 'craftgate': return <FormCraftgate {...{ register, provider }} />;
              case 'paywall': return <FormPaywall {...{ register, provider }} />;
              default: return <p>Lütfen bir sağlayıcı seçin.</p>;
            }
          })()}
        </form>
        <p>
          ES Events, entegre ödeme sistemi çözüm ortağı olarak
          {' '}
          <img style={{ margin: '0 0.5rem' }} src="https://www.itspaywall.com/assets/img/logos/paywall-logo-3.png" height={24} alt="Paywall" />
          {' '}
          ile çalışmaktadır. Detaylı bilgi almak için
          {' '}
          <a target="_blank" href="https://www.paywall.one/#/" rel="noreferrer">Paywall</a>
          {' '}
          web sitesini ziyaret edin.
        </p>
      </main>
    ) : <LoadingIndicator />
  );
};

export default observer(Provider);
