/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { Link } from 'wouter';
import strings from '../../localization';

const ReferencesTable = ({ data, borderless }) => (
  <main className={`p-0 table-responsive ${borderless ? '' : 'main'}`} id="references-table">
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">Referans Kodu</th>
          <th scope="col">Referans Türü</th>
          <th scope="col">Kullanan Kişi</th>
          <th scope="col">Oluşturma Tarihi</th>
          <th scope="col">Kullanma Tarihi</th>
        </tr>
      </thead>
      <tbody>
        {data.map((entity) => (
          <tr key={entity._id}>
            <td>{`${entity.code.slice(0, 4)} - ${entity.code.slice(4, 7)} - ${entity.code.slice(7, 16)}`}</td>
            <td>{strings[`entity-${entity.type}`]}</td>
            <td>
              {entity.usedBy.name ? (
                <Link to={`/accounts/${entity.usedBy._id}`}>{entity.usedBy.name}</Link>
              ) : 'N/A'}
            </td>
            <td>
              {`${dayjs.utc(entity.createdAt).format('D MMMM HH:mm')}`}
            </td>
            <td>
              {entity.usedBy.name ? `${dayjs.utc(entity.updatedAt).format('D MMMM HH:mm')}` : 'N/A' }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </main>
);

export default ReferencesTable;
