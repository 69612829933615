/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';

import { LoadingIndicator } from '../../../../components/utils';
import { get, put } from '../../../../libs/client';
import stores from '../../../../libs/stores';

// import strings from '../../../../localization';

// import EventBuyer from './EventBuyer';
import { SaleTickets } from '../../../../components/tables';
// import Transaction from './Transaction';

const buttons = {
  pending: 'ÖDEME BEKLENİYOR',
  '3ds': '3D SECURE BEKLENİYOR',
  success: 'TÜM BİLETLERİ İADE ET',
  failure: 'ÖDEME BAŞARISIZ',
  refund: 'ÖDEME İADE EDİLMİŞ',
};

const Sale = ({ params }) => {
  const [sale, setSale] = useState(null);

  const GetSale = () => get(`/sales/${params.id}`)
    .then(({ data }) => setSale(data))
    .catch((err) => console.log(err));

  const Refund = (ids) => put('/sales/tickets/refund', { sale: sale._id, tickets: ids })
    .then((data) => {
      console.log(data);
      GetSale();
    })
    .catch((err) => console.log(err));

  useEffect(() => { GetSale(); }, []);
  useEffect(() => {
    if (sale) {
      stores.setAppbar({
        left: (<h2 className="m-0">{`${sale?.dump?.PaymentId} Numaralı Satış`}</h2>),
        right: (
          <button
            type="button"
            id="sale-refund-all"
            // disabled={sale?.status !== 'success'}
            className={`btn btn-${sale?.status === 'success' ? 'danger' : 'success'} mr-2`}
            onClick={() => Refund(sale.tickets.map((t) => t._id))}
          >
            <i className={`fa fa-${sale?.status === 'success' ? 'close' : 'check'} mr-2`} />
            {buttons[sale?.status]}
          </button>
        ),
      });
    }
  }, [sale]);

  return (
    sale
      ? (
        <main className="grid">
          <SaleTickets
            data={sale?.tickets}
            currency={sale?.sale?.currency}
            onRefund={(id) => Refund([id])}
          />
          {/* <EventBuyer show={step === 0} sale={sale} /> */}
          {/* <Transaction show={step === 2} transaction={sale?.dump} /> */}
        </main>
      )
      : <LoadingIndicator />
  );
};

export default Sale;
