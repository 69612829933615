/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { Link } from 'wouter';

const ContractTile = ({ contract }) => (
  <Link to={`/contracts/${contract._id}`}>
    <main className="main pointer d-flex flex-column" id="contract">
      <h4 className="text-2-line mb-4">{contract?.name}</h4>
      <div className="d-flex flex-row align-items-end flex-grow-1">
        <h6 className="flex-grow-1">Son düzenleme: </h6>
        <h6>{dayjs.utc(contract?.updatedAt).format('DD MMMM YYYY, HH:mm')}</h6>
      </div>
    </main>
  </Link>
);

export default ContractTile;
