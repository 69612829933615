import { useEffect, useState } from 'react';
import {
  Switch, Route, Redirect, useLocation,
  // useLocation,
} from 'wouter';
import { observer } from 'remx';
import { Step } from 'react-rtg';

import Home from './Home';
import Events from './Events';
import Event from './Events/Event';
import EventSale from './Events/EventSale';
import Sales from './Sales';
import Sale from './Sales/Sale';
import Providers from './Providers';
import Provider from './Providers/Provider';
import Contracts from './Contracts';
import Contract from './Contracts/Contract';
import Profile from './Profile/index';
import Payments from './Payments';
import Cards from './Cards';
import Card from './Cards/Card';

import Help from '../../components/utils/Help';
import { Sidebar, Appbar } from '../../components/navigation';
import { LoadingIndicator } from '../../components/utils';
import { setToken } from '../../libs/storage';
import { get } from '../../libs/client';
import stores from '../../libs/stores';

const routes = [
  ['/home', Home],
  ['/events', Events],
  ['/events/:id', Event],
  ['/events/:eid/sales/:id', EventSale],
  ['/reports', Sales],
  ['/reports/:id', Sale],
  ['/payments', Payments],
  ['/providers', Providers],
  ['/providers/:id', Provider],
  ['/cards', Cards],
  ['/cards/:id', Card],
  ['/contracts', Contracts],
  ['/contracts/:id', Contract],
  ['/profile', Profile],
];

const Panel = () => {
  const [location] = useLocation();
  const [help, setHelp] = useState(false);

  useEffect(() => get('/accounts/me')
    .then((account) => get('/organizers/me')
      .then((organizer) => stores.setAccount({ ...account.data, profile: organizer.data }))
      .catch(() => setToken('')))
    .catch(() => setToken('')),
  []);

  // useEffect(() => { stores.setTutorial((() => null)); }, [location]);

  return (stores.getAccount() ? (
    <div className="app-wrapper">
      {location === '/home' && (
        <Help open={help} setOpen={setHelp}>
          <Step placement="center">
            <p>
              {`Merhaba ${stores.getAccount().profile.corporate.legalName}, ES Events'e hoşgeldiniz.`}
            </p>
          </Step>
          <Step selector="#sidebar-link-home" placement="right">
            <p>
              Bu sayfa, organizatör hesabına giriş yaptığın zaman
              genel satış bilgilerini görebileceğin sayfadır
            </p>
          </Step>
          <Step selector="#sidebar-link-events" placement="right">
            <p>
              Buradan bütün etkinliklerinizi görebilir ve yönetebilirsiniz
            </p>
          </Step>
          <Step selector="#sidebar-link-sales" placement="right">
            <p>
              Buradan etkilikleriniz hakkında gerçekleşen tüm satışları görebilirsiniz
            </p>
          </Step>
          <Step selector="#sidebar-link-profile" placement="right">
            <p>
              Bu sayfadan, ES Events organizatör hesabınızı düzenleyebilirsiniz
            </p>
          </Step>
          <Step selector="#sidebar-link-providers" placement="right">
            <p>
              Bu sayfadan, etkinlikleriniz için ödeme altyapınızı düzenleyebilirsiniz
            </p>
          </Step>
          <Step selector="#sidebar-link-contracts" placement="right">
            <p>
              Bu sayfadan, etkinlikleriniz için bilet satış sözleşmelerinizi görüntüleyebilirsiniz
            </p>
          </Step>
          <Step selector="#sidebar-link-logout" placement="right">
            <p>
              Bu buton ile güvenli çıkış yapabilirsiniz
            </p>
          </Step>
          <Step selector="#general-help" placement="top-right">
            <p>
              Bulunduğunuz herhangi bir sayfada yardıma ihtiyacınız olursa buradan erişebilirsiniz
            </p>
          </Step>
          <Step placement="center">
            <p>
              ES Events kullandığınız için teşekkürler.
            </p>
          </Step>
        </Help>
      )}
      <Sidebar />
      <div className="grid app-content">
        <Appbar />
        <Switch>
          {routes.map(([path, component]) => <Route key={path} {...{ path, component }} />)}
          <Redirect to="/home" />
        </Switch>
      </div>
    </div>
  ) : <LoadingIndicator />);
};

export default observer(Panel);
