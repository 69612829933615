import { useState, useEffect } from 'react';
import { observer } from 'remx';
import { Step } from 'react-rtg';
import { Link } from 'wouter';

import Help from '../../../components/utils/Help';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { ContractTile } from '../../../components/tiles';

import { get } from '../../../libs/client';
import stores from '../../../libs/stores';

const Cards = () => {
  const [contracts, setContracts] = useState(null);

  const [help, setHelp] = useState(null);
  stores.setTutorial(() => { setHelp(true); });

  const GetContracts = () => get('/organizers/contracts/me')
    .then(({ data }) => setContracts(data))
    .catch((err) => console.log(err));

  useEffect(() => {
    GetContracts();
    stores.setAppbar({
      left: (<h2 className="m-0">Kayıtlı Kartlarım</h2>),
      right: (
        <Link to="/cards/new">
          <button type="button" className="btn btn-primary" id="add-contract">
            <i className="fa fa-plus mr-2" />
            KART EKLE
          </button>
        </Link>
      ),
    });
  }, []);

  if (contracts) {
    if (contracts.length) {
      return (
        <main className="grid">
          {/* HELP START  */}
          <Help open={help} setOpen={setHelp}>
            <Step selector="#contract" placement="right">
              <p>Bu kısımda satış sözleşmelerinizi görebilir ve düzenleyebilirsiniz</p>
            </Step>
            <Step selector="#add-contract" placement="bottom">
              <p>Satış yapmak için en az bir satış sözleşmeyesine ihtiyacınız var</p>
            </Step>
            <Step placement="center">
              <p>Farklı etkinlikleriniz için birden fazla satış sözleşmesi ekleyebilirsiniz</p>
            </Step>
          </Help>
          {/* HELP END  */}

          <section className="grid grid-3">
            {contracts.map((contract) => <ContractTile {...{ contract }} />)}
          </section>
        </main>
      );
    } return (
      <CenteredInformation
        title="Satış sözleşmesi yok"
        subtitle="Satış yapmak için kullanıcılarınızın okuyabileceği bir sözleşme eklemeniz gerekiyor."
        fixed
      />
    );
  } return <LoadingIndicator />;
};

export default observer(Cards);
