/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */

import { useState, useEffect } from 'react';
import { get } from '../../libs/client';

// import strings from '../../localization';

const AsyncEntitySelect = ({
  label, defaultValue, className, placeholder,
  style, disabled, entity, onChange, onChangeEntity, id,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState(defaultValue?._id);
  const [query, setQuery] = useState(defaultValue?.name);
  const [options, setOptions] = useState(null);

  const SearchEntity = () => get(`/${entity}/search?page=0&limit=10&active=true&name=${query || ' '}`)
    .then(({ data }) => setOptions(data)).catch((err) => console.log(err));

  useEffect(() => onChange && value && onChange(value), [value]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setOptions(null);
      SearchEntity();
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  return (
    <div
      className={`form-group mb-0 position-relative ${className || ''}`}
      style={style}
      id={id}
      // WTF ???
      onFocus={() => setShowOptions(true)}
    >
      {showOptions && (
        <div
          className="center"
          onClick={() => setShowOptions(false)}
          style={{ width: '100vh', height: '100vh', position: 'fixed' }}
        />
      )}
      {label && <label>{label}</label>}
      <div className="input-group">
        <input
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={value?.name}
          value={query}
          className="form-control"
          onChange={({ target }) => setQuery(target.value)}
        />
      </div>
      <div
        className="dropdown-menu async-entity-dropdown"
        style={{ minHeight: options === null ? 48 : 'unset', display: showOptions ? 'block' : 'none' }}
      >
        {options === null && (
        <div className="position-absolute center">
          <span
            className="spinner-border text-primary"
            role="status"
          />
        </div>
        )}
        {options?.length < 1
          ? (<p className="dropdown-menu-item text-center">Birşey bulunamadı</p>)
          : options?.map((item) => (
            <a
              key={item._id}
              className="dropdown-menu-item pointer d-flex flex-row align-items-center w-100"
              onClick={() => {
                setValue(item._id);
                setShowOptions(false);
                setQuery(item.name);
                if (onChangeEntity) onChangeEntity(item);
              }}
            >
              <img src={item.logo || item.image} height="32px" width="32px" alt="logo" />
              <p className="ml-2 fl-1 text-1-line">
                {item.name}
              </p>
            </a>
          ))}
      </div>
    </div>
  );
};

export default AsyncEntitySelect;
