/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'remx';
import { Step } from 'react-rtg';

import dayjs from 'dayjs';
import { get } from '../../../../../libs/client';

import Help from '../../../../../components/utils/Help';
import Session from './Session';
import stores from '../../../../../libs/stores';

const SessionEmptyHelp = ({ help, setHelp }) => (
  <Help open={help} setOpen={setHelp}>
    <Step placement="center">
      <p>Bu sayfanın yardımını görmek için lütfen önce bir satış oluşturun</p>
    </Step>
    <Step selector="#create-sale" placement="bottom">
      <p>Eğer satış bilgilerinizi girdiyseniz buradan oluşturabilirsiniz</p>
    </Step>
  </Help>
);

const SessionNoneHelp = ({ help, setHelp }) => (
  <Help open={help} setOpen={setHelp}>
    <Step placement="center">
      <p>Etkinlik satışlarınıza başlamak için en az bir seans eklemeniz lazım</p>
    </Step>
    <Step selector="#create-session" placement="bottom">
      <p>Seans oluşturmak için aşağıdaki butona tıklayabilirsiniz.</p>
    </Step>
    <Step selector="#create-session" placement="bottom">
      <p>Bir etkinlik için birden fazla seans oluşturabilirsiniz.</p>
    </Step>
  </Help>
);

const SessionHelp = ({ help, setHelp }) => (
  <Help open={help} setOpen={setHelp}>
    <Step selector="#session-start" placement="left">
      <p>Buradan seansın başlangıç tarihini ve saatini girebilirsiniz</p>
    </Step>
    <Step selector="#session-start" placement="left">
      <p>Uyarı: En erken 1 hafta önce etkinlik açabilirsiniz</p>
    </Step>
    <Step selector="#session-end" placement="left">
      <p>Buradan da bitiş tarihini ve saatini girebilirsiniz.</p>
    </Step>
    <Step selector="#session-seating" placement="left">
      <p>Buradan etkinliğinizin oturma planı olup olmadığını belirleyebilirsiniz</p>
    </Step>
    <Step selector="#session-stock" placement="left">
      <p>Buradan toplam satılacak bilet sayısını belirleyebilirsiniz</p>
    </Step>
    <Step selector="#session-sold" placement="left">
      <p>Burada satılan bilet sayınız görünür</p>
    </Step>
    <Step selector="#session-left" placement="left">
      <p>Burada satışta kaç bilet kaldığı görünür</p>
    </Step>
    <Step selector="#ticket-add" placement="left">
      <p>Satış detaylarından sonra bilet oluşturmak için buraya tıklayabilirsiniz</p>
    </Step>
    <Step selector="#ticket-category" placement="left">
      <p>Biletleri gruplamak için bir kategori isimi verebilirsiniz</p>
    </Step>
    <Step selector="#ticket-name" placement="left">
      <p>Bilet türü ismini buradan verebilirsiniz</p>
    </Step>
    <Step selector="#ticket-price" placement="left">
      <p>Bilet fiyatını buradan belirleyebilirsiniz</p>
    </Step>
    <Step selector="#ticket-stock" placement="left">
      <p>Bilet stoğunu buradan belirleyebilirsiniz</p>
    </Step>
    <Step selector="#ticket-sold" placement="left">
      <p>Kaç bilet satıldığını burdan görebilirsiniz</p>
    </Step>
    <Step selector="#ticket-sale" placement="left">
      <p>Satılacak kaç bilet kaldığını burdan görebilirsiniz</p>
    </Step>
    <Step selector="#ticket-status" placement="left">
      <p>Buradan bilet satışlarını geçici olarak durdurabilirsiniz</p>
    </Step>
    <Step selector="#ticket-delete" placement="left">
      <p>Buradan bilet satışlarını geçici kalıcı olarak durdurabilirsiniz</p>
    </Step>
    <Step selector="#select-seat" placement="top">
      <p>
        Eğer oturma planlı etkinliğiniz varsa koltukları
        <svg style={{ marginLeft: '0.5rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2" viewBox="0 0 16 16">
          <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5V5h4V1H1.5zM5 6H1v4h4V6zm1 4h4V6H6v4zm-1 1H1v3.5a.5.5 0 0 0 .5.5H5v-4zm1 0v4h4v-4H6zm5 0v4h3.5a.5.5 0 0 0 .5-.5V11h-4zm0-1h4V6h-4v4zm0-5h4V1.5a.5.5 0 0 0-.5-.5H11v4zm-1 0V1H6v4h4z" />
        </svg>
        butonuna tıklayıp seçebilirsiniz
      </p>
    </Step>
    <Step selector="#create-session" placement="right">
      <p>Etkinliğiniz için oluşturduğunuz seansı kaydetmeyi unutmayın!</p>
    </Step>
    <Step placement="center">
      <p>Biletlerinizi ayarladıktan sonra ES Events onayı ve Mekan onayını alıp satışa başlayabilirsiniz!</p>
    </Step>
  </Help>
);

const Sessions = ({
  sale,
  venue, onSessionSelect,
  show, params, paid,
}) => {
  const [sessions, setSessions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [help, setHelp] = useState(false);

  const GetSessions = useCallback(() => get(`/events/${params.eid}/sales/${params.id}/sessions`)
    .then(({ data }) => setSessions(data))
    .catch((err) => console.log(err)), []);

  const GetSeatingPlans = () => get(`/venues/${venue}/seating`)
    .then(({ data }) => setPlans(data))
    .catch((err) => console.log(err));

  useEffect(() => venue && GetSeatingPlans(venue), [venue]);
  useEffect(() => {
    if (params.id !== 'new') {
      GetSessions();
    }
  }, []);

  useEffect(() => {
    if (show) {
      stores.setTutorial(() => { setHelp(true); });
    }
  }, [show]);

  return (
    <section className="grid" style={{ display: show ? 'grid' : 'none' }}>
      {/* HELP START  */}
      {params.id === 'new'
        ? (<SessionEmptyHelp {...{ help, setHelp }} />)
        : (sessions.length > 0
          ? <SessionHelp {...{ help, setHelp }} />
          : <SessionNoneHelp {...{ help, setHelp }} />
        )}
      {/* HELP END  */}
      {sessions.sort((a, b) => (dayjs(a.start).isAfter(dayjs(b.start)) ? 1 : -1)).map((session) => (
        <Session
          help={help}
          key={session?._id || Math.random()}
          session={session}
          params={params}
          plans={plans}
          sale={sale}
          onOpen={() => onSessionSelect(session)}
          onRemove={() => setSessions(sessions.filter(({ _id }) => _id !== session._id))}
        />
      ))}
      <button
        id="create-session"
        type="button"
        className="btn btn-primary"
        disabled={params.id === 'new' || (sale?.vendor_confirmation === 'confirmed' && sale?.venue_confirmation === 'confirmed') || paid?.status === 'success'}
        onClick={() => {
          if (help === true) {
            setTimeout(() => setHelp(true), 250);
          }
          setHelp(false);
          setSessions([...sessions, {
            _id: `new${sessions.length}`,
            tickets: [],
          }]);
        }}
      >
        <i className="fa fa-plus mr-2" />
        {(() => {
          if (params.id === 'new') return 'LÜTFEN ÖNCE SATIŞ OLUŞTURUN';
          if (paid?.status === 'success') return 'ÖDEMESİ YAPILAN ETKİNLİKLERE SEANS EKLENEMEZ';
          return 'YENİ SEANS EKLE';
        })()}
      </button>
    </section>
  );
};

export default observer(Sessions);
